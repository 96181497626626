import React, { useState,useEffect } from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";

const STRUKTURORG_URL = "/struktur-organisasi";

const StrukturOrganisasi = () =>{
    const [data, setData] = useState([]);
    const [dataGuru, setDataGuru] = useState([]);

    useEffect(() => {
        fetchStruktur();
      }, []);

      const fetchStruktur = async () => {
        try {
          // Menggunakan Promise.all untuk menggabungkan dua permintaan
          const [strukturResp, guruResp] = await Promise.all([
            axios.get(STRUKTURORG_URL, {
              headers: {
                "Accept": "application/json",
              },
            }), 
          ]);
    
          // Mengatur data struktur organisasi dan data guru ke dalam state
          setData(strukturResp?.data?.data);
          setDataGuru(guruResp?.data?.data);
        //   console.log(guruResp?.data?.data);
        } catch (error) {
          console.log(error?.response?.data);
        }
      };
      
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/struktur-organisasi" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Struktur-Organisasi</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Struktur Organisasi</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"15px auto" }}/>
                            <div className="row">
                                <div className="col" >
                                    
                                    <div className="row mb-3" style={{ backgroundColor:"#D9D9D9" }}>
                                        <div className="col ">
                                            <img src={'https://smkn6jakarta.sch.id/app/storage/struktur-organisasi/'+data.image} alt="gambar struktur" className="img-fluid"></img>
                                        </div>
                                    </div>
                                    <div className="row " style={{ backgroundColor:"#D9D9D9" }}>
                                        <div className="col">
                                        <div id="carouselExample" class="carousel slide">
                                            <div class="carousel-inner" >
                                                <div className="carousel-item active">
                                                    <img src="/img/Foto Carousel - 2.jpg" className="d-block w-100" alt="..." style={{ height:"303px",objectFit:"cover" }}/>
                                                    </div>
                                                    <div className="carousel-item">
                                                    <img src="/img/Foto Carousel - 3.jpg" className="d-block w-100" alt="..." style={{ height:"303px",objectFit:"cover" }}/>
                                                </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col ms-3" style={{ backgroundColor:"#D9D9D9" }}>
                                 <img src={'https://smkn6jakarta.sch.id/app/storage/struktur-organisasi/'+data.desc_image} alt="gambar struktur" className="img-fluid"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default StrukturOrganisasi;