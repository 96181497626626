import React from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";

const VisiMisi = () =>{
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/visi-misi" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Visi-Misi</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Visi</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <p style={{ textAlign:"justify",fontSize:"20px" }}>Menjadi SMK yang Berprestasi, Pembentuk Sumber Daya Manusia Berkualitas, Berakhlak Mulia dan Berwawasan Internasional.</p>
                            <h1 style={{ fontWeight:"bold",color:"#061745",marginTop:"50px" }}>Misi</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <ol class="list-group list-group-numbered list-group-flush" style={{ fontSize:"20px",textAlign:"justify" }}>
                                <li class="list-group-item">Mengembangkan kultur sekolah untuk memberdayakan peserta didik agar mejadi insan yang berakhlak mulia, berkarakter, kreatif dan kompetitif; </li>
                                <li class="list-group-item">Meningkatkan kompetensi peserta didik untuk memasuki dunia kerja, baik di tingkat nasional maupun internasional berdasarkan imtak dan iptek; </li>
                                <li class="list-group-item">Meningkatkan Mutu kompetensi pendidik dan tenaga kependidikan yang profesional;</li>
                                <li class="list-group-item">Menanamkan sikap disiplin, kepekaan sosial, semangat nasionalisme dan patriotisme kepada seluruh warga sekolah;</li>
                                <li class="list-group-item">Meningkatkan sarana dan prasarana untuk mendukung proses pembelajaran yang optimal;</li>
                                <li class="list-group-item">Meningkatkan pelayanan untuk memenuhi kepuasan pelanggan.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default VisiMisi;