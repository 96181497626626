import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter,Routes,Route} from "react-router-dom";
import {AuthProvider} from "./auth/AuthProvider"
import SEO from './component/SEO';

export * from './shapes';
export { SEO };

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/*" element={<App />}/>
            </Routes>
        </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
, document.getElementById("root")
);

reportWebVitals();
