import React from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";

const SejarahSekolah = () =>{
     const data = [
        {no: 1,nama: "Darsono Gondowiharjo",masaJabatan:"1959 – 1962"},
        {no: 2,nama: "Drs. Sunarso",masaJabatan:"1962 – 1964"},
        {no: 3,nama: "Drs. Sembada",masaJabatan:"1964 – 1965"},
        {no: 4,nama: "Oloan Siregar",masaJabatan:"1965 – 1976"},
        {no: 5,nama: "Drs. Zubir Rauf",masaJabatan:"1976 – 1982"},
        {no: 6,nama: "Drs. Suwito, M.M.",masaJabatan:"1982 – 1989"},
        {no: 7,nama: "Drs. Iljas Surasudjaja",masaJabatan:"1989 – 1990"},
        {no: 8,nama: "Drs. Kaharuddin Kadir",masaJabatan:"1990 – 1995"},
        {no: 9,nama: "Drs. Sumardi",masaJabatan:"1995 – 2000"},
        {no: 10,nama: "Dra. Esih Setianingsih",masaJabatan:"2000 – 2005"},
        {no: 11,nama: "Drs. Waluyo Hadi",masaJabatan:"2005 – 2008"},
        {no: 12,nama: "Dra. Latifah Hanum",masaJabatan:"2008 – 2010"},
        {no: 13,nama: "Drs. H. Husin, M.M.",masaJabatan:"2010 – 2012"},
        {no: 14,nama: "Drs. H.Badrun Sjabirin, M.M",masaJabatan:"2012 – 2014"},
        {no: 15,nama: "Drs. Sudiono, M.M.",masaJabatan:"2014 – 2015"},
        {no: 16,nama: "Drs. Rianto Ritonga",masaJabatan:"2015 – 2017"},
        {no: 17,nama: "Aziza, M.Pd.",masaJabatan:"2017 – 2022"},
        {no: 18,nama: "Darminto, M.Par.",masaJabatan:"2022 – sekarang"},
    ];
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/sejarah-sekolah" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Sejarah Sekolah</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Sejarah Sekolah</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <img src="/img/img-smkn6/Profil/Gambar_SejarahSekolah-1.png" alt="" style={{ maxWidth:"100%", height:"auto", margin:"40px 0px",width:"1150px" }}></img>
                            <p style={{ textAlign:"justify",fontSize:"20px" }}>
                            Berdasarkan buku yang telah diterbitkan oleh Direktorat Jenderal Pendidikan Dasar dan Menengah dengan judul “SMK dari Masa Ke Masa” tahun 2015 dikatakan bahwa pada saat Pembangunan Lima Tahun (Pelita) ke-IV (Tahun 1983-1989) pengembangan pendidikan kejuruan pada dasarnya ditujukan untuk menyiapkan tenaga kejuruan yang akan dipekerjakan dan tenaga kerja mandiri. Upaya penyediaan tenaga kerja untuk dipekerjakan di industri menuntut ditingkatkannya hubungan sekolah dengan dunia industri. Program yang dikembangkan pada dasarnya disesuaikan dengan kebutuhan pembangunan regional ataupun nasional, antara lain di bidang pertanian (khususnya perikanan dan bioteknologi), pariwisata dan yang terkait (seperti industri kerajinan), rekayasa (terutama elektronika dan transportasi), dan perbankan. 
                            <br/><br/>
                            Pada tahun 1988, rencana serta program-program telah tampak tersusun rapi dalam konteks upaya menyediakan tenaga terampil pada jenjang menengah. Telah tergambar bagaimana kaitan tingkat kemahiran yang selayaknya ada berdasarkan piramida ketenagaan yang lazim berlaku dalam dunia kerja. Bahkan secara kuantitatif juga sudah diupayakan target jumlah tenaga yang harus dihasilkan tahun demi tahun dalam rangka pembangunan ekonomi Indonesia. Pengelompokan kedalam bidang-bidang garapan juga sudah terbentuk; ada kelompok Teknik, Pertanian, Kerajinan dan Kesenian. Bidang-bidang lainnya yaitu perdagangan, administrasi perkantoran, boga, busana, pariwisata, dan sebagainya dikelompokkan dalam satu wadah yang menggunakan nama “Kejuruan” tentu dalam pengertian yang lebih sempit dari istilah “kejuruan” yang mencakup semuanya. Akhirnya seiring perkembangan terkini maka perekembangan sekolah kejuruan pun semakin meluas hingga saat ini.
                            <img src="/img/img-smkn6/Profil/Gambar_SejarahSekolah-2.png" alt="" style={{ maxWidth:"100%", height:"auto", margin:"40px 0px",width:"1150px" }}></img>
                            Pada mulanya SMEP 3 telah dibuka lebih dahulu dari SMEA 3 yang lokasinya sama, sehingga untuk mengembangkan kedua sekolah ini oleh panitia yang dibentuk oleh Persatuan Orang Tua Murid (POM) waktu itu, diusahakan untuk membangun satu gedung baru yang dapat di gunakan kedua sekolah ini. Persatuan Orang Tua Murid   berhasil dan dengan bantuan sepenuhnya dari organisasi Koperasi Pembantik Bersama Jakarta (KPBD), dapatlah dilaksanakan peletakan batu pertama gedung SMEP/SMEA Negeri 3 pada tanggal 23 Maret 1959, sehingga pada tanggal 1 Agustus 1960 pada saat gedung belum rampung keseluruhannya, SMEA 3 dan SMEP 3 menempati satu gedung, gedung baru ini rampung keseluruhannya pada bulan Januari 1960 dan pada tanggal 6 Januari 1960 peresmian di lakukan oleh Bapak Menteri Koperasi yang pada waktu itu dijabat oleh Mayor Jenderal Achmadi, peresmian gedung ini dihadiri juga oleh Bapak Koperasi Dr. Moh. Hatta, pada saat didirikannya SMEA 3 menerima murid hanya 78 orang siswa. 
                            <br/><br/>
                            Sekolah Menengah Ekonomi Atas (SMEA) Negeri 3 Jakarta di dirikan pada tanggal 1 Agustus 1959 dengan SK Pendiriannya No.986/B.3/kdj tertanggal 31 Juli 1959 di Jalan Prof. Joko Sutono SH, No.2A Kebayoran Baru Jakarta Selatan 12160, tanggal SK Pendirian 13 September 1959 dan mulai tahun 1997 SMEA Negeri 3 berubah namanya menjadi SMK Negeri 6 Jakarta. Kepala SMK Negeri 6 Jakarta silih berganti mengikuti periode kepemimpinannya, antara lain:                                
                            </p>
                            <div class="table-responsive mt-5">
                                    <table class="table align-middle ">                                    
                                    <thead>
                                        <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Nama Kepala Sekolah</th>
                                        <th scope="col">Masa Jabatan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((datas)=>(
                                        <tr key={datas.no}>
                                        <th scope="row">{datas.no}</th>
                                        <td>{datas.nama}</td>
                                        <td>{datas.masaJabatan}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default SejarahSekolah;