import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import "./css/login.css";
import axios from "../api/axios";
// import useAuth from "../auth/useAuth";
import Swal from 'sweetalert2';
import { useSignIn } from 'react-auth-kit'

const LOGIN_URL = "/login";

const Login = () =>{
    // const { setAuth } = useAuth();
    const signIn = useSignIn()
  const navigate = useNavigate();

  const userRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);
  

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resp = await axios.post(LOGIN_URL, JSON.stringify({ username, password }), {
        headers: { 
          "Content-type": "application/json",
          "Accept" : "appliaction/json",
          // "X-CSRF-TOKEN": token,
       },
        // withCredentials:true
      });
      
      signIn({
        token :resp?.data?.token,
        expiresIn: 7200,
        tokenType:"Bearer",
        authState:{username:resp?.data?.username}
      })
      Toast.fire({
        icon: 'success',
        title: 'Berhasil Masuk'
      })
      navigate("/admin", { replace: true });
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: error?.response?.data?.message
      });
      console.log(error?.response?.data);
    }
  };


    return(
        <> 
        <div className="Login">
        <div className="container-fluid">
                <form className=" p-5" onSubmit={handleSubmit}>
                <div className="card">
                    <img src="/img/img-smkn6/Logo-SMKN6JKT-HD.png" alt="" style={{ width:"100px",height:"auto" }}/>
                    <div className="inputBox">
                        <input type="text" id="username" name="username" ref={userRef} value={username} onChange={(e) => setUsername(e.target.value)} required={true}/>
                        <span className="user">Username</span>
                    </div>

                    <div className="inputBox">
                        <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required={true}/>
                        <span>Password</span>
                    </div>
                    <div className="inputBox">
                    {/* <input type="hidden" name="_token" value={token}></input> */}
                    </div>

                    <button className="enter" type="submit">login{" "}</button>
                </div>
                </form>
            </div>
        </div>
        </>
        
    )
}

export default Login;