import React from "react";
import { Link } from "react-router-dom";

const Kontak = () =>{
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2"></i> <Link to="/kontak" style={{ color:"#000000" }}>Kontak</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col text-center">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Hubungi Kami</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px",margin:"auto" }}/>
                            <div className="row mt-5 align-items-center">
                                <div className="col">
                                   <img src="/img/img-smkn6/Logo-SMKN6JKT-HD.png" alt="" className="img-fluid" style={{ width:"50%",height:"auto" }}/>
                                </div>
                                <div className="col-md-4 col-sm-12 g-5 text-start">
                                    <div className="row">
                                        <div className="col-4 g-3">
                                            <div className="icon">
                                            <i className="bi bi-buildings text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-8 g-3">
                                            <h5 style={{ fontWeight:"bold" }}>SMK NEGERI 6 JAKARTA</h5>
                                            <p>Jl. Prof. Joko Sutono SH No. 2A</p>
                                        </div>                
                                    </div>
                                    <div className="row">
                                        <div className="col-4 g-3">
                                            <div className="icon">
                                            <i className="bi bi-telephone text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-8 g-3">
                                            <h5 style={{ fontWeight:"bold" }}>Telepon</h5>
                                            <Link to="tel:0217208718">(021) 7208718</Link>
                                        </div>                
                                    </div>
                                    <div className="row">
                                        <div className="col-4 g-3">
                                            <div className="icon">
                                                <i className="bi bi-envelope text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-8 g-3">
                                            <h5 style={{ fontWeight:"bold" }}>Email</h5>
                                            <Link to="mailto:smk6@smkn6jakarta.sch.id">smk6@smkn6jakarta.sch.id</Link>
                                        </div>          
                                    </div>
                                    <div className="row">
                                        <div className="col-4 g-3">
                                            <div className="icon">
                                                <i className="bi bi-instagram text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-8 g-3">
                                            <h5 style={{ fontWeight:"bold" }}>Instagram</h5>
                                            <Link to="https://www.instagram.com/smkn6jkt/">@smkn6jkt</Link>
                                        </div>                
                                    </div>
                                    <div className="row">
                                        <div className="col-4 g-3">
                                            <div className="icon">
                                                <i className="bi bi-tiktok text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-8 g-3">
                                            <h5 style={{ fontWeight:"bold" }}>TikTok</h5>
                                            <Link to="https://www.tiktok.com/@smkn6jkt">@smkn6jkt</Link>
                                        </div>                
                                    </div>
                                    <div className="row">
                                        <div className="col-4 g-3">
                                            <div className="icon">
                                                <i className="bi bi-youtube text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-8 g-3">
                                            <h5 style={{ fontWeight:"bold" }}>YouTube</h5>
                                            <Link to="https://www.youtube.com/@smknegeri6jakarta">SMK Negeri 6 Jakarta</Link>
                                        </div>                
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Kontak;