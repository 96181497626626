import React from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";

const LambangMars = () =>{
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/lambang-mars" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Lambang-dan-Mars</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Lambang</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"25px auto" }}/>
                            <img src="../img/img-smkn6/Logo-SMKN6JKT-HD.png" alt="" style={{ width:"324px",height:"auto",maxWidth:"100%" }}/>
                            <h1 style={{ fontWeight:"bold",color:"#061745",marginTop:"70px" }}>Mars SMKN 6 Jakarta</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"25px auto" }}/>
                            <p style={{ fontSize:"20px" }}>SMK 6<br/>
                                Sekolah kita<br/>
                                Majulah<br/>
                                Maju terus...<br/>
                                Sekolah bermutu<br/>
                                Berprestasi<br/>
                                Berakhlak mulia<br/>
                                Berwawasan internasional<br/>
                                Itulah visinya<br/>
                                Kami bertekad menjadi juara <br/>
                                Demi masa depan<br/>
                                Kami bertekad menjadi juara<br/>
                                Demi masa depan<br/>
                                Kami bertekad menjadi juara<br/>
                                Demi masa depan</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default LambangMars;