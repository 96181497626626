import React,{useEffect,useState} from "react";
import "../css/kurikulum.css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";


const KBM_URL = "/jadwal-kbm";

const JadwalKBM = () =>{
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchKBM();
      }, []);


      const fetchKBM = async () => {
        try{
            const resp = await axios.get(KBM_URL, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setData(resp.data?.data);
            //   console.log(resp.data?.data.url);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/kurikulum/jadwal-kbm" style={{ color:"#000000" }}>Kurikulum <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Jadwal-KBM</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Jadwal Kegiatan Belajar Mengajar ( KBM )</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <p style={{ fontSize:"22px",marginTop:"40px" }}>Semester Ganjil Tahun Pelajaran {data.year}</p>
                                <a href={data.url} target="_blank">
                            <button className="button mt-5" id="download">
                                Download Jadwal KBM
                            </button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default JadwalKBM;