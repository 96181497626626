import React,{useState} from "react";
import { getCookie } from "../../../utils/cookieUtils";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';

const KALENDER_URL = "/kalender-akademik";
const KalenderPendidikanAdmin = () =>{
    const [url, setUrl] = useState("");
    const [tahunPendidikan, setTahunPendidikan] = useState("");
    const token = getCookie("_auth");

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    const updateKalender =async (e)=>{
        e.preventDefault();
      const formData = new FormData();
      formData.append("url", url);
      formData.append("year", tahunPendidikan);

      try{
        const resp = await axios.post(`${KALENDER_URL}?_method=PATCH`, formData, {
            headers: {
              "Content-type": "multipart/form-data",
              "Accept": "appliaction/json",
              "Authorization": `Bearer ${token}`
            },
          });
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Mengupload Link'
          });
          setUrl("");
          setTahunPendidikan("");
      }catch(error){
        Toast.fire({
            icon: 'error',
            title: error?.response?.data?.message
          });
          console.log(error?.response?.data);
      }

    }

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Ubah Kalender Pendidikan</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            
                            <form className="text-start" onSubmit={updateKalender}>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Link Drive</label>
                                <input 
                                type="url" 
                                className="form-control" 
                                id="UploadLink" 
                                name="url"
                                // ref={userRef} 
                                value={url} 
                                onChange={(e) => setUrl(e.target.value)} 
                                required={true}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Tahun Pendidikan</label>
                                <input 
                                type="text" 
                                placeholder="contoh 2023/2024"
                                className="form-control" 
                                id="UploadLink" 
                                name="tahunpendidikan"
                                // ref={userRef} 
                                value={tahunPendidikan} 
                                onChange={(e) => setTahunPendidikan(e.target.value)} 
                                required={true}
                                />                                
                            </div>
                            <button type="submit" className="btn btn-primary mt-3" style={{ background:"#061745",padding:"10px 70px"  }}>Submit{}</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default KalenderPendidikanAdmin;