import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import DOMPurify from 'dompurify';
import SEO from '@americanexpress/react-seo';
import { Config } from "../../config"

const BERITA_URL = "/berita-sekolah";

const DetailBerita = () => {
  const { id } = useParams(); // Mengambil nilai ID dari URL menggunakan useParams()

  const userRef = useRef();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchBerita();
  }, []);

  const fetchBerita = async () => {
    try {
      const resp = await axios.get(BERITA_URL, {
        headers: {
          "Accept": "appliaction/json",
        },
        // withCredentials:true
      });
      setPosts(resp.data?.data);
      // console.log(resp.data?.data?.[0].date);
      // console.log(resp.data?.data);
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  // Konfigurasi halaman
  const postsPerPage = 3;
  const totalPages = Math.ceil(posts.length / postsPerPage);
  const indexOfLastPosts = currentPage * postsPerPage;
  const indexOfFirstPosts = indexOfLastPosts - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPosts, indexOfLastPosts);

  //fungsi split tanggal
  const formatDate = (date) => {
    const dateParts = date.split("-");

    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

    // Ubah angka bulan menjadi nama bulan
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = parseInt(month) - 1;
    const monthName = monthNames[monthIndex];

    return {
      day,
      month: monthName,
      year,
    };
  };

  const sanitizeAndTrimContent = (content) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    const plainTextContent = sanitizedContent.replace(/<[^>]+>/g, '');
    if (plainTextContent.length > 100) {
        return `${plainTextContent.substring(0, 100)}...`;
    }
    return plainTextContent;
  };

  const post = posts.find((post) => post.id === Number(id)); // Menemukan post dengan ID yang cocok

  if (!post) {
    return <div>Post tidak ditemukan</div>; // Menampilkan pesan jika post tidak ditemukan
  }
  const { day, month, year } = formatDate(post.date);

  const title = `${Config.siteName}: ${post.title}`;
  const description = `${ sanitizeAndTrimContent(post.content) }`;
  return (
    <div>
      <SEO
        title={ title }
        description={ description }
        keywords={['smkn 6', 'smkn 6 jakarta', 'smk negeri 6', 'smk 6']}
        siteUrl={ Config.baseURL }
        image={{
          src: `${Config.storage}/berita-sekolah/'${post.image}`
        }}
        openGraph={{
          title: { title },
          description: { description },
          image: {
            src: `${Config.storage}/berita-sekolah/'${post.image}`,
            alt: `${Config.siteName}`,
          }
        }}
        twitterCard={{
          title: { title },
          description: { description },
          image: {
            src: `${Config.storage}/berita-sekolah/'${post.image}`,
            alt: `${Config.siteName}`,
          }
        }}
      />

      <div className="container-fluid p-0">
        <div className="breadcumbs">
            <Link to="/" style={{ color: "#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize: "10px" }}></i> <Link to="/postingan/berita" style={{ color: "#000000" }}>Postingan <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize: "10px" }}></i> Berita</Link>
            <hr style={{ width: "100%" }} />
        </div>
      </div>

      <div id="blog" className="blog">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-8">
              <article className="blog-details">
                <div className="post-img">
                  <img src={'https://smkn6jakarta.sch.id/app/storage/berita-sekolah/' + post.image} className="img-fluid" />
                </div>

                <h2 className="title">{post.title}</h2>

                <div className="meta-top">
                  <ul>
                    <li class="d-flex align-items-center"><i class="bi bi-person"></i> @smkn6jkt</li>
                    <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <time dateTime={`${year}-${month}-${day}`}>{day + " , " + month + " , " + year}</time></li>
                  </ul>
                </div>

                <div className="content">
                  <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}></p>
                </div>
              </article>
            </div>
            <div className="col-lg-4 sidebar">
              <div className="widgets-container">

                <div className="ads-widget widget-item">
                  <Link to="/" target="_blank">
                    <img src="https://www.svgrepo.com/show/435687/help-20px.svg" alt="Ads" />
                  </Link>
                </div>

                <div className="recent-posts-widget widget-item">
                  <h3 class="widget-title">Recent Posts</h3>

                  {currentPosts.map((post) => {
                    const { day, month, year } = formatDate(post.date);
                    return(
                      <>
                      <div key={post.id} className="post-item">
                        <Link
                          to={`/postingan/berita/${post.id}`}
                          className="post-links"
                        >
                          <img src={`${Config.storage}/berita-sekolah/${post.image}`} alt={Config.siteName} className="img-thumbnail flex-shrink-0" />
                          <h4>{ post.title }</h4>
                          <time dateTime={`${year}-${month}-${day}`}>{day + " , " + month + " , " + year}</time>
                        </Link>
                      </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailBerita;