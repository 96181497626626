import React from "react";

import { Link } from "react-router-dom";

const DesainKomunikasiVisual = () =>{
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/kurikulum/kompetensi-keahlian/desain-komunikasi-visual" style={{ color:"#000000" }}>Kurikulum <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Kompetensi-Keahlian <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Desain-Komunikasi-Visual</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center mt-4" style={{ padding:"0px 40px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Desain Komunikasi Visual (DKV)</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <div className="row">
                                <div className="col">
                                    <div id="carouselExampleIndicators" className="carousel slide">
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        </div>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                            <img src="/img/Gambar.png" className="d-block w-100" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}/>
                                            </div>
                                            <div className="carousel-item">
                                            <img src="/img/logosmk6.jpg" className="d-block w-100" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}/>
                                            </div>
                                            <div className="carousel-item">
                                            <img src="/img/Gambar.png" className="d-block w-100" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}/>
                                            </div>
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col-md-8">
                                <div className="d-flex justify-content-between">
                                <p style={{ textAlign: "justify", marginRight: "20px" }}>
                                    Program keahlian yang membekali siswa dengan keahlian mengolah beberapa media komunikasi baik verbal maupun non verbal seperti Teks, Animasi, Audio, Video, Gambar (Grafik) untuk menghasilkan produk multimedia seperti Musik, Game, Film, Entertainment/Hiburan menggunakan teknologi sehingga menjadi sebuah produk yang komunikatif. Lulusan program keahlian Desain Komunikasi Visual ini berpeluang untuk menjadi Video Editor, Kameramen, Sound Enginering, Visualizer, Web Desainer, dan atau bekerja di dunia Pertelevisian, Radio, Production House (PH), atau di industri-industri Multi Media lainnya.
                                </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div id="carouselExampleIndicators2" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                    <img src="/img/Gambar.png" className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                    <img src="/img/logosmk6.jpg" className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                    <img src="/img/Gambar.png" className="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                                </div>
                            </div>
                            </div>

                            <div className="row mt-5" style={{ backgroundColor:"#061745", padding:"20px 20px",color:"#fff" }}>
                                <div className="col text-start">
                                <h1 style={{ fontWeight:"bold"}}>Materi Keahlian</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px 0px" }}/>   
                                <ul style={{ fontSize:"20px" }}>
                                <li>Materi 1</li>
                                <li>Materi 2</li>
                                <li>Materi 3</li>
                                </ul>
                                </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col" style={{textAlign:"justify"}}>
                                <h1 style={{ fontWeight:"bold"}}>Profil Kemampuan Tamatan</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px 0px" }}/>   
                                <ul style={{ fontSize:"20px" }}>
                                <li>Profil Kemampuan Tamatan 1</li>
                                <li>Profil Kemampuan Tamatan 2</li>
                                <li>Profil Kemampuan Tamatan 3</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default DesainKomunikasiVisual;