import React, { useState,useRef,useEffect } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import axios from "../../api/axios";
import DOMPurify from 'dompurify';

const AGENDA_URL = "/agenda-sekolah";


const DetailAgenda = () => {
    const { id } = useParams(); // Mengambil nilai ID dari URL menggunakan useParams()

    const userRef = useRef();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetchAgenda();
      }, []);

      const fetchAgenda = async () => {
        try{
            const resp = await axios.get(AGENDA_URL, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setPosts(resp.data?.data);
              // console.log(resp.data?.data?.[0].date);
              // console.log(resp.data?.data?.[0].id);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };

      //fungsi split tanggal
  const formatDate = (date) => {
    const dateParts = date.split("-");

    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

    // Ubah angka bulan menjadi nama bulan
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = parseInt(month) - 1;
    const monthName = monthNames[monthIndex];

    return {
      day,
      month: monthName,
      year,
    };
  };

  const post = posts.find((post) => post.id === Number(id)); // Menemukan post dengan ID yang cocok

  if (!post) {
    return <div>Post tidak ditemukan</div>; // Menampilkan pesan jika post tidak ditemukan
  }

  const { day, month, year } = formatDate(post.date);

  return (
    <div>
      <div className="container-fluid p-0">
            <div className="prestasi-siswa-detail">
                <div className="bg">  
                        <div className="row">
                            <div className="col">
                                <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                            </div>
                        </div>
                </div>
                <div className="content">
                    <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                        <div className="row">
                            <div className="col" >
                                <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/postingan/agenda" style={{ color:"#000000" }}>Postingan <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Agenda</Link>
                            <hr style={{ width:"100%" }}/>
                            </div>
                        </div>
                        <div className="row mb-5" style={{ padding:"10px 80px" }}>
                            <div className="col">                            
                                
                                    <div className="row mt-5 mb-5">
                                        <div className="col">
                                            <img src={'https://smkn6jakarta.sch.id/app/storage/agenda-sekolah/'+post.image} alt="" style={{ width:"100%",height:"auto" }}/>
                                            <div style={{ backgroundColor: "#061745",marginTop: "-100px",marginLeft: "10px",marginBottom:"40px",position: "relative",width:"10%" }} className="text-white p-2 rounded">
                                                <p className="mb-0 fw-bold fs-2 text-center">{day}</p>
                                                <p className="mb-0 mt-0 text-center" style={{ fontSize: "small" }}>{month+" , "+year}</p>
                                            </div>
                                            
                                            <h1 style={{ color: "#002147;",fontWeight:"bold"}}>{post.title}</h1>
                                            <h6 style={{ color:"#666666",fontSize:"20px",marginTop:"30px" }}><i className="bi bi-people-fill"></i> Admin</h6>
                                            <hr className="mb-3 mt-4"/>
                                            <p className="card-text" dangerouslySetInnerHTML={{ __html :DOMPurify.sanitize(post.content) }}></p>
                                        </div>
                                </div>
                                
                                <Link to="/postingan/agenda">Kembali Ke Agenda</Link>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default DetailAgenda;