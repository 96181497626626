import React from "react";
import { Link } from "react-router-dom";

const Bkk = () =>{
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/humas/bkk" style={{ color:"#000000" }}>Humas <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> BKK</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Bursa kerja Khusus (Bkk)</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <img src="../img/UnderConstruction_Website.png" alt="" style={{ maxWidth:"100%", height:"auto", margin:"40px 0px",width:"1150px" }}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default Bkk;