import React, { useState,useEffect,useRef } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "../../api/axios";


const GALERI_URL = "/galeri-sekolah/list";

const Galeri = () =>{
    const userRef = useRef();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchGaleri();
      }, []);

      const fetchGaleri = async () => {
        try{
            const resp = await axios.get(GALERI_URL, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setData(resp.data?.data);
            //   console.log(resp.data?.data);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };

    // Konfigurasi halaman
    const photosPerPage = 9;
    const totalPages = Math.ceil(data.length / photosPerPage);
    const indexOfLastPhoto = currentPage * photosPerPage;
    const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
    const currentPhotos = data.slice(indexOfFirstPhoto, indexOfLastPhoto);

    // Fungsi untuk pindah ke halaman berikutnya
    const goToNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    // Fungsi untuk pindah ke halaman sebelumnya
    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    // Fungsi untuk pindah ke halaman tertentu
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // console.log(data);

    //preview gambar
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };
    return(
       
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content sarpras">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/postingan/galeri" style={{ color:"#000000" }}>Postingan <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Galeri</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col text-center">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Galeri </h1>
                            <hr className="m-auto" style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <div className="sarpras mt-5">
                                <div className="baristiga row row-cols-1 row-cols-md-3  align-items-center">
                                    {currentPhotos.map((photo) => (
                                    <div key={photo.id} className="col g-3">
                                        <div className="position-relative">
                                        <div className="image d-flex align-items-center justify-content-center">
                                            <img 
                                            src={'https://smkn6jakarta.sch.id/app/storage/galeri-sekolah/'+photo.image} 
                                            alt="Gambar" 
                                            className="img-fluid img-content" 
                                            style={{ width: "500px", height: "250px",cursor:"pointer" ,objectFit:"cover"}} 
                                            onClick={() => handleImageClick('https://smkn6jakarta.sch.id/app/storage/galeri-sekolah/' + photo.image)}
                                            />
                                            <div className="content">
                                            <h5 style={{ fontWeight: "bold", fontSize: "30px" }}>{photo.title}</h5>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    {/* menampilkan preview gambar  */}
                    {selectedImage && (
                        <div className="image-preview-overlay" onClick={() => setSelectedImage(null)}>
                            <img src={selectedImage} alt="Preview" className="image-preview" />
                        </div>
                    )}

                     {/* Tombol navigasi halaman */}
                    <div className="row">
                    <div className="col text-center">
                        {currentPage > 1 && (
                        <button className="btn" onClick={goToPreviousPage}>
                            <i class="bi bi-caret-left-fill"></i>
                        </button>
                        )}
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <span
                            key={pageNumber}
                            className={`page-number ${pageNumber === currentPage ? "active" : ""}`}
                            onClick={() => goToPage(pageNumber)}
                        >
                            {pageNumber}
                        </span>
                        ))}
                        {currentPage < totalPages && (
                        <button className="btn" onClick={goToNextPage}>
                            <i class="bi bi-caret-right-fill"></i>
                        </button>
                        )}
                    </div>
                    </div>

                    
                </div>
            </div>
        </div>
        
        
    )
}

export default Galeri;