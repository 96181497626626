import React,{useState, useEffect, useRef,} from "react";
import { Link,  useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getCookie } from "../../../utils/cookieUtils";

const JURUSAN_URL = "/kompetensi-keahlian"

const KompetensiKeahlianForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userRef = useRef();
  const [namakompetensiKeahlian, setNamakompetensiKeahlian] = useState("");
  const [galerikompetensiKeahlian, setGalerikompetensiKeahlian] = useState("");
  const [gambarkompetensiKeahlian, setGambarkompetensiKeahlian] = useState("");
  const [quillContent, setQuillContent] = useState("");
  const [materikompetensiKeahlian, setMaterikompetensiKeahlian] = useState("");
  const [profilTamatankompetensiKeahlian, setProfilTamatankompetensiKeahlian] = useState("");
  const token = getCookie("_auth");

  const location = useLocation();
  const { isFormDisabled } = location.state || {};

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
    if (id) {
        // Fetch existing data based on ID
        fetchJurusan();
      }
  }, [id]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  //get
  const fetchJurusan = async () => {
    try {
      const resp = await axios.get(`${JURUSAN_URL}/${id}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Accept": "appliaction/json",
        },
      });
      setNamakompetensiKeahlian(resp.data?.data?.name);
      setGalerikompetensiKeahlian(resp.data?.data?.image);
      setQuillContent(resp.data?.data?.description);
      setMaterikompetensiKeahlian(resp.data?.data?.subject);
      setProfilTamatankompetensiKeahlian(resp.data?.data?.graduate);

      // console.log(resp.data?.data?.image);
      
    //   console.log("image :" +image);
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  const formData = new FormData();
  formData.append("name", namakompetensiKeahlian);
  formData.append("descrition", quillContent);
  formData.append("file1", galerikompetensiKeahlian);
  formData.append("file2", gambarkompetensiKeahlian);
  formData.append("description", quillContent);
  formData.append("subject", materikompetensiKeahlian);
  formData.append("graduate", profilTamatankompetensiKeahlian);

  

    try {
      if(id){
          // Edit
          const resp = await axios.post(`${JURUSAN_URL}/${id}?_method=PATCH`, formData, {
            headers: {
              "Content-type": "multipart/form-data",
              "Accept": "appliaction/json",
              "Authorization": `Bearer ${token}`
            },
          });
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Mengedit Jurusan'
          });
          navigate("/admin/kompetensi-keahlian-admin", { replace: true });
        } 
        // Post
        else {
          const resp = await axios.post(JURUSAN_URL, formData, {
            headers: { 
              "Content-type": "multipart/form-data",
              "Accept" : "appliaction/json",
              "Authorization" : `Bearer ${token}`
          },
            // withCredentials:true
          });
    
          setNamakompetensiKeahlian("");
          setGalerikompetensiKeahlian("");
          setGambarkompetensiKeahlian("");
          setMaterikompetensiKeahlian("");
          setNamakompetensiKeahlian("");
          setQuillContent("");
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Menambahkan Jurusan'
          });
          navigate("/admin/kompetensi-keahlian-admin", { replace: true });
        }
      } catch (error) {
          Toast.fire({
            icon: 'error',
            title: error?.response?.data?.message
          });
          console.log(error?.response?.data);
        }
      };

  return (
    <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col ">
                            <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>{id ? "Edit Kompetensi Keahlian" : "Tambah Kompetensi Keahlian"}</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <form onSubmit={handleSubmit}>
                            <div className="form-group">
                            <label htmlFor="namakompetensiKeahlian">Nama Kompetensi Keahlian:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="namakompetensiKeahlian"
                                value={namakompetensiKeahlian}
                                onChange={(e) => setNamakompetensiKeahlian(e.target.value)}
                            />
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="GambarkompetensiKeahlian">Gambar Representasi Kompetensi Keahlian:</label>
                            <input
                                type="file"
                                className="form-control"
                                id="GambarkompetensiKeahlian"
                                name="GambarkompetensiKeahlian"
                                accept="image/*"
                                onChange={(e) => setGambarkompetensiKeahlian(e.target.files[0])}
                            />
                            <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div> 
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="GambarkompetensiKeahlian">Gambar Kompetensi Keahlian:</label>
                            <input
                                type="file"
                                className="form-control"
                                id="GambarkompetensiKeahlian"
                                accept="image/*"
                                onChange={(e) => setGalerikompetensiKeahlian(e.target.files[0])}
                            />
                            <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div> 
                            </div>
                            
                            <div className="form-group mt-4">
                            <label htmlFor="DeskripsikompetensiKeahlian">Deskripsi Kompetensi Keahlian:</label>
                            <ReactQuill 
                             value={quillContent}
                             disabled={isFormDisabled}
                             onChange={setQuillContent}
                            />
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="MaterikompetensiKeahlian">Materi Kompetensi Keahlian:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="MaterikompetensiKeahlian"
                                value={materikompetensiKeahlian}
                                onChange={(e) => setMaterikompetensiKeahlian(e.target.value)}
                            />
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="ProfilTamatankompetensiKeahlian">Profil Tamatan Kompetensi Keahlian:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ProfilTamatankompetensiKeahlian"
                                value={profilTamatankompetensiKeahlian}
                                onChange={(e) => setProfilTamatankompetensiKeahlian(e.target.value)}
                            />
                            </div>
                            <div className="form-group">
                            <button type="submit" className="btn btn-primary mt-3" disabled={id ? true : false}>Submit{" "}</button>
                            <button type="submit" className="btn btn-warning ms-3 mt-3" disabled={id ? false : true}>Edit{" "}</button>
                            <Link to="/admin/kompetensi-keahlian-admin" className="btn btn-primary mt-3 ms-3" style={{ background:"#061745"}}>Kembali</Link>
                            </div>
                        </form>
                            
                                                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

  );
};

export default KompetensiKeahlianForm;
