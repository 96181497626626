import React, { useState,useRef,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import DOMPurify from 'dompurify';

const BERITA_URL = "/prestasi-peserta-didik";

const PrestasiSiswa = () => {
  const userRef = useRef();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetchBerita();
      }, []);

      const fetchBerita = async () => {
        try{
            const resp = await axios.get(BERITA_URL, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setPosts(resp.data?.data);
              // console.log(resp.data?.data?.[0].date);
              // console.log(resp.data?.data );
        }catch (error) {
            console.log(error?.response?.data);
          }
      };


  const MAX_CONTENT_LENGTH = 200; // Jumlah maksimal karakter content yang ingin ditampilkan

  // Konfigurasi halaman
  const postsPerPage = 3;
  const totalPages = Math.ceil(posts.length / postsPerPage);
  const indexOfLastPosts = currentPage * postsPerPage;
  const indexOfFirstPosts = indexOfLastPosts - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPosts, indexOfLastPosts);

  // Fungsi untuk pindah ke halaman berikutnya
  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Fungsi untuk pindah ke halaman sebelumnya
  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Fungsi untuk pindah ke halaman tertentu
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Fungsi untuk menampilkan post secara penuh berdasarkan ID
  const viewFullPost = (postId) => {
    navigate(`/prestasi-siswa/${postId}`); // Menggunakan navigate untuk navigasi ke halaman baru dengan ID post
  };

  //fungsi split tanggal
  const formatDate = (date) => {
    const dateParts = date.split("-");

    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

    // Ubah angka bulan menjadi nama bulan
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const monthIndex = parseInt(month) - 1;
    const monthName = monthNames[monthIndex];

    return day+" "+monthName+" "+year
  };

  const sanitizeAndTrimContent = (content) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    if (sanitizedContent.length > MAX_CONTENT_LENGTH) {
      return `${sanitizedContent.substring(0, MAX_CONTENT_LENGTH)}...`;
    }
    return sanitizedContent;
  };
  

  return (
    <>
      <div className="container-fluid p-0">
        <div className="prestasi-siswa">
          <div className="bg">
            <div className="row">
              <div className="col">
                <img
                  src="/img/HEADER.png"
                  alt=""
                  className="mr-3 me-3 w-100 d-block"
                />
              </div>
            </div>
          </div>
          <div className="content">
            <div
              className="container shadow"
              style={{ backgroundColor: "#fff", padding: "20px 40px" }}
            >
              <div className="row">
                <div className="col">
                  <Link to="/" style={{ color: "#000000" }}>
                    Beranda
                  </Link>{" "}
                  <i
                    className="bi bi-circle text-warning ms-2 me-2"
                    style={{ fontSize: "10px" }}
                  ></i>{" "}
                  <Link
                    to="/kesiswaan/prestasi-siswa"
                    style={{ color: "#000000" }}
                  >
                    Kesiswaan{" "}
                    <i
                      className="bi bi-circle text-warning ms-2 me-2"
                      style={{ fontSize: "10px" }}
                    ></i>{" "}
                    Prestasi Siswa
                  </Link>
                  <hr style={{ width: "100%" }} />
                </div>
              </div>
              <div
                className="row mb-5"
                style={{ padding: "10px 80px" }}
              >
                <div className="col">
                  <h1
                    style={{ fontWeight: "bold", color: "#061745" }}
                  >
                    Prestasi Siswa
                  </h1>
                  <hr
                    style={{
                      width: "15%",
                      border: "none",
                      borderTop: "4px solid #FFB606",
                      opacity: "100%",
                      marginTop: "5px",
                    }}
                  />

                  {currentPosts.map((post) => (
                    <div
                      key={post.id}
                      className="row mt-5"
                    >
                      <div className="col" >
                        <Link
                          to={`/kesiswaan/prestasi-siswa/${post.id}`}
                        >
                          <img
                            src={'https://smkn6jakarta.sch.id/app/storage/prestasi-peserta-didik/'+post.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                          <h4
                            style={{
                              color: "#666666",
                            }}
                            className="mt-3 mb-4"
                          >
                            Skala {post.scale}
                          </h4>
                          <h1
                            style={{
                              color: "#002147",
                              fontWeight: "bold",
                            }}
                          >
                            {post.title}
                          </h1>
                          <p
                            style={{
                              color: "#666666",
                              fontSize: "17px",
                              marginTop: "30px",
                            }}
                          >
                            <i className="bi bi-calendar2-week"></i>{" "}
                            {formatDate(post.date)}
                          </p>
                          <h6
                            style={{
                              color: "#666666",
                              fontSize: "20px",
                            }}
                          >
                            <i className="bi bi-people-fill"></i>{" "}
                            {post.name}
                          </h6>
                          <p className="card-text" style={{ color:"#666666" }} dangerouslySetInnerHTML={{__html: sanitizeAndTrimContent(post.content),}}></p>
                          <hr className="mt-5" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Tombol navigasi halaman */}
              <div className="row">
                    <div className="col text-center">
                        {currentPage > 1 && (
                        <button className="btn" onClick={goToPreviousPage}>
                            <i class="bi bi-caret-left-fill"></i>
                        </button>
                        )}
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <span
                            key={pageNumber}
                            className={`page-number ${pageNumber === currentPage ? "active" : ""}`}
                            onClick={() => goToPage(pageNumber)}
                        >
                            {pageNumber}
                        </span>
                        ))}
                        {currentPage < totalPages && (
                        <button className="btn" onClick={goToNextPage}>
                            <i class="bi bi-caret-right-fill"></i>
                        </button>
                        )}
                    </div>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrestasiSiswa;
