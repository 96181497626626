import React,{useState, useEffect, useRef,} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const EKSKUL_URL = "/ekstrakurikuler";

const EkstrakurikulerForm = () => {
  const { id } = useParams();
  const userRef = useRef();
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const token = getCookie("_auth");
  const navigate = useNavigate();

  const location = useLocation();
  const { isFormDisabled } = location.state || {};



  useEffect(() => {
      if (userRef.current) {
        userRef.current.focus();
      }
      if (id) {
          // Fetch existing data based on ID
          fetchEkskul();
        }
    }, [id]);

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    const fetchEkskul = async () => {
      try {
        const resp = await axios.get(`${EKSKUL_URL}/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "appliaction/json",
          },
        });
      //   console.log(resp.data);
        setName(resp.data?.data?.name);
        setImage(resp.data?.data?.image);
        // console.log(resp.data?.data?.image);
        
      //   console.log("image :" +image);
      } catch (error) {
        console.log(error?.response?.data);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);

      try {
        if(id){
          // Edit
    const resp = await axios.post(`${EKSKUL_URL}/${id}?_method=PATCH`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
        "Accept": "appliaction/json",
        "Authorization": `Bearer ${token}`
      },
    });
    Toast.fire({
      icon: 'success',
      title: 'Berhasil Mengedit Ekstrakurikuler'
    });
    navigate("/admin/ubah-ekstrakurikuler", { replace: true });
  } 
  // Post
  else {
    const resp = await axios.post(EKSKUL_URL, formData, {
      headers: { 
        "Content-type": "multipart/form-data",
        "Accept" : "appliaction/json",
        "Authorization" : `Bearer ${token}`
     },
      // withCredentials:true
    });
    setName("");
    setFile("");
    Toast.fire({
      icon: 'success',
      title: 'Berhasil Menambahkan Ekstrakurikuler'
    });
    navigate("/admin/ubah-ekstrakurikuler", { replace: true });
  }
    } catch (error) {
        Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message
        });
        console.log(error?.response?.data);
      }
    };


  return (
    <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col ">
                            <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>{id ? "Edit Ekstrakurikuler" : "Tambah Ekstrakurikuler"}</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            {image && (
                            <div className="mb-3">
                                <label className="form-label">Gambar saat ini:</label>
                                <img src={'https://smkn6jakarta.sch.id/app/storage/ekstrakurikuler/'+image} alt="Gambar saat ini" className="img-fluid" />
                            </div>
                            )}

                            <form className="text-start" onSubmit={handleSubmit} enctype="multipart/form-data">
                            <div className="mb-3">
                                <label for="UploadTitle" className="form-label">Masukkan Nama Ekstrakurikuler</label>
                                <input 
                                type="text" 
                                className="form-control" 
                                id="UploadTitle" 
                                name="title"
                                ref={userRef} 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                required={isFormDisabled}
                                disabled={isFormDisabled}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadFile" className="form-label">Masukkan Gambar</label>
                                <input 
                                type="file" 
                                className="form-control" 
                                id="UploadFile"
                                name="file"          
                                accept="image/*"              
                                onChange={(e) => setFile(e.target.files[0])}
                                required={isFormDisabled} 
                                disabled={isFormDisabled}
                                />
                                <div id="UploadFile" className="form-text">ukuran maksimal 2mb</div>                                 
                            </div>
                            <button type="submit" className="btn btn-primary mt-3" disabled={id ? true : false}>Submit{" "}</button>
                            <button type="submit" className="btn btn-warning ms-3 mt-3" disabled={id?false:true}>Edit{" "}</button>
                            <Link to="/admin/ubah-ekstrakurikuler" className="btn btn-primary mt-3 ms-3" style={{ background:"#061745" }}>Kembali</Link>
                            </form>
                            
                                                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

  );
};

export default EkstrakurikulerForm;
