import React,{useState,useEffect} from "react";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const GURU_URL = "/jumlah-pendidik";

const UbahPendidik = () =>{

  const [pendidikPns, setPendidikPns] = useState("");
  const [pendidikCPns, setPendidikCPns] = useState("");
  const [pendidikKontrak, setPendidikKontrak] = useState("");
  const [pendidikHonorer, setPendidikHonorer] = useState("");
  
  const token = getCookie("_auth");

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  // Mengambil nilai dari database
  useEffect(() => {
    const fetchData = async () => {
    try {
        const response = await axios.get(GURU_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
        });
        const { pns, cpns, kontrak, honorer } = response.data?.data;
        setPendidikPns(pns);
        setPendidikCPns(cpns);
        setPendidikKontrak(kontrak);
        setPendidikHonorer(honorer);
    } catch (error) {
        console.log(error);
    }
    };

    fetchData();
}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
      formData.append("pns", pendidikPns);
      formData.append("cpns", pendidikCPns);
      formData.append("kontrak", pendidikKontrak);
      formData.append("honorer", pendidikHonorer);

    try{
        const resp = await axios.post(`${GURU_URL}?_method=PATCH`, formData, {
            headers: { 
              "Accept" : "appliaction/json",
              "Authorization" : `Bearer ${token}`
           },
            // withCredentials:true
          });
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Menambahkan Jumlah Guru'
          });
    }catch(error){
        Toast.fire({
            icon: 'error',
            title: error?.response?.data?.message
          });
          console.log(error?.response?.data);
    }
  };

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Ubah Pendidik</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            
                            <form className="text-start" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Guru PNS</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                aria-describedby="emailHelp"
                                id="pendidikPns"
                                name="pendidikPns"
                                value={pendidikPns}
                                onChange={(e) => setPendidikPns(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Guru CPNS</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                aria-describedby="emailHelp"
                                id="pendidikCPns"
                                name="pendidikCPns"
                                value={pendidikCPns}
                                onChange={(e) => setPendidikCPns(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Guru Kontrak</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                aria-describedby="emailHelp"
                                id="pendidikKontrak"
                                name="pendidikKontrak"
                                value={pendidikKontrak}
                                onChange={(e) => setPendidikKontrak(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Guru Honorer</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                aria-describedby="emailHelp"
                                id="pendidikHonorer"
                                name="pendidikHonorer"
                                value={pendidikHonorer}
                                onChange={(e) => setPendidikHonorer(e.target.value)}
                                />                                
                            </div>
                            <button type="submit" className="btn btn-primary mt-3" style={{ background:"#061745",padding:"10px 70px"  }}>Submit{}</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default UbahPendidik;