import React , { useState,useEffect }from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";


const ME_URL = "/me";

const DashboardAdmin = () =>{
    const accessToken = localStorage.getItem("accessToken");
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchME();
      }, []);

      const fetchME = async () => {
        try{
            const resp = await axios.get(ME_URL, {
                headers: { 
                    "Accept": "appliaction/json",
                    "Authorization" : `Bearer ${accessToken}`
               },
                // withCredentials:true
              });
              setData(resp.data);
              // console.log(resp.data);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda Admin</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>{data.id ? `Halo ${data.username}`:"Halo Admin"}</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default DashboardAdmin;