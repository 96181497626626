import React from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";

const TentangSekolah = () =>{
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="tentang-sekolah">
                <div className="bg">  
                        <div className="row">
                            <div className="col">
                                <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                            </div>
                        </div>
                </div>
                <div className="content">
                    <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                        <div className="row">
                            <div className="col" >
                                <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/tentang-sekolah" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Tentang-Sekolah</Link>
                            <hr style={{ width:"100%" }}/>
                            </div>
                        </div>
                        <div className="row mb-5" style={{ padding:"10px 80px" }}>
                            <div className="col">
                                <h1 style={{ fontWeight:"bold",color:"#061745" }}>Tentang Sekolah</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                                <img src="/img/img-smkn6/IMG_4894.png" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}></img>
                                <div class="table-responsive mt-5">
                                    <table class="table align-middle ">                                    
                                        <tbody>
                                        <tr>
                                            <td>Nama Sekolah </td>
                                            <td>:   SMK Negeri 6 Jakarta</td>
                                        </tr>
                                        <tr>
                                            <td>Nomor Statistik Sekolah (NSS) </td>
                                            <td>:   34.101.63.06.006</td>
                                        </tr>
                                        <tr>
                                            <td>NPSN </td>
                                            <td>:   20102579</td>
                                        </tr>
                                        <tr>
                                            <td>No. SK Pendirian </td>
                                            <td>:   986/B.3/KDJ</td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal SK Pendirian </td>
                                            <td>:  18 November 2003</td>
                                        </tr>
                                        <tr>
                                            <td>Luas Lahan Keseluruhan </td>
                                            <td>:  5766 m2</td>
                                        </tr>
                                        <tr>
                                            <td>Luas Bangunan </td>
                                            <td>Panjang (54 m2), Lebar (24 m2), </td>
                                        </tr>
                                        <tr>
                                            <td>Akreditasi </td>
                                            <td>:   A  atau Unggul (NO. 1347/BAN-SM/SK/2021)</td>
                                        </tr>
                                        <tr>
                                            <td>Alamat </td>
                                            <td>:    Jl. Prof. Joko Sutono SH No.1, RT.1/RW.2, Melawai, Kec. Kebayoran Baru, Kota Jakarta Selatan, DKI Jakarta, Indonesia 12160</td>
                                        </tr>
                                        <tr>
                                            <td>Fax </td>
                                            <td>:   021 - 7237010</td>
                                        </tr>
                                        <tr>
                                            <td>Telepon </td>
                                            <td>:    021 - 7208718</td>
                                        </tr>
                                        <tr>
                                            <td>Email </td>
                                            <td>:   smk6@smkn6dki.or.id</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default TentangSekolah;