import React,{useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const GALERI_URL = "/galeri-sekolah/list";

const GaleriAdmin = () =>{
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  
  const [data, setData] = useState([]);
  const token = getCookie("_auth");

  useEffect(() => {
    fetchGaleri();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      navigate('/admin/galeri-admin');
    }
  }, [data]);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const handleAction = (id,status) => {
    // Navigate to FormGaleri component with action and id as query parameters
    navigate(`/admin/form-galeri/${id}`, { state: { isFormDisabled: status } });
  };

    const fetchGaleri = async () => {
      try{
          const resp = await axios.get(GALERI_URL, {
              headers: { 
                "Authorization" : `Bearer ${token}`
             },
              // withCredentials:true
            });
            setData(resp.data?.data);
            // console.log(resp.data?.data);
      }catch (error) {
          console.log(error?.response?.data);
        }
    };
    


    const hapusGaleri = async (id) => {
      try {
        const result = await Swal.fire({
          title: 'Apakah Anda yakin ingin menghapus gambar ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-danger mx-2',
            cancelButton: 'btn btn-secondary mx-2',
          },
          buttonsStyling: false,
        });
    
        if (result.isConfirmed) {
          const resp = await axios.delete(`https://smkn6jakarta.sch.id/app/api/galeri-sekolah/${id}`, {
            headers: {
              "Authorization": `Bearer ${token}`
            },
          });
    
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Hapus Gambar'
          });
    
          fetchGaleri();
        } else {
          Swal.fire('Batal', 'Gambar tidak dihapus', 'info');
        }
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message
        });
        console.log(error?.response?.data);
      }
    };
    
    useEffect(() => {
      setCurrentPage(1); // Reset currentPage when data changes
    }, [data]);

    // Konfigurasi halaman
    const dataPerPage = 5;
    const totalPages = Math.ceil(data.length / dataPerPage);
    const indexOfLastPhoto = currentPage * dataPerPage;
    const indexOfFirstPhoto = indexOfLastPhoto - dataPerPage;
    const currentdata = data.slice(indexOfFirstPhoto, indexOfLastPhoto);

    // Fungsi untuk pindah ke halaman berikutnya
    const goToNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    // Fungsi untuk pindah ke halaman sebelumnya
    const goToPreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
    };

    // Fungsi untuk pindah ke halaman tertentu
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

  return(
      <> 
      <div className="container-fluid p-0">
          <div className="bg">  
                  <div className="row">
                      <div className="col">
                          <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                      </div>
                  </div>
          </div>
          <div className="content">
              <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                  <div className="row mb-5" style={{ padding:"10px 80px" }}>
                      <div className="col ">
                          <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>Galeri Sekolah</h1>
                          <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                          <Link to="/admin/form-galeri" className="btn btn-primary mt-3" style={{ marginLeft: 0,background:"#061745" }}><i className="bi bi-plus"></i> Tambah Foto Galeri</Link>
                          
                          <table className="table table-bordered mt-2 align-middle text-center">
                          <thead>
                              <tr>
                              <th scope="col">No</th>
                              <th scope="col">Nama Gambar</th>
                              <th scope="col">Lihat</th>
                              <th scope="col">Edit</th>
                              <th scope="col">Hapus</th>
                              </tr>
                          </thead>
                          <tbody>
                          {currentdata.map((item, index) => (
                              <tr key={index}>
                              <th scope="row">{index + indexOfFirstPhoto + 1}</th>
                               <td>{item.title}</td>{/*title*/}
                              <td><button type="submit" className="btn btn-primary" onClick={() => handleAction(item.id,true)}><i className="bi bi-eye"></i></button></td>{/*lihat berdasarkan id*/}  
                              <td><button type="submit" className="btn btn-warning" onClick={() => handleAction(item.id,false)}><i className="bi bi-pencil"></i></button></td>{/*edit berdasarkan id*/}                                                               
                              <td><button type="submit" className="btn btn-danger" onClick={() =>hapusGaleri(item.id)}><i className="bi bi-trash3"></i></button></td>{/*hapus berdasarkan id*/}                                                               
                              </tr>
                           ))} 
                          </tbody>
                          </table>                            
                      </div>
                  </div>

                  {/* Tombol navigasi halaman */}
                  <div className="row">
                    <div className="col text-center">
                        {currentPage > 1 && (
                        <button className="btn" onClick={goToPreviousPage}>
                            <i className="bi bi-caret-left-fill"></i>
                        </button>
                        )}
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <span
                            key={pageNumber}
                            className={`page-number ${pageNumber === currentPage ? "active" : ""}`}
                            onClick={() => goToPage(pageNumber)}
                        >
                            {pageNumber}
                        </span>
                        ))}
                        {currentPage < totalPages && (
                        <button className="btn" onClick={goToNextPage}>
                            <i className="bi bi-caret-right-fill"></i>
                        </button>
                        )}
                    </div>
                    </div>
              </div>
          </div>
      </div>
      </>
      
  )


}

export default GaleriAdmin;