import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// 

const Navbar = () =>{
    return (
        <>
        <nav className="navbar navbar-expand-lg p-3 navbar-dark" style={{ backgroundColor: "#061745" }}>
            <div className="container" >
            <Link className="navbar-brand" to="/">
                <img src="/img/img-smkn6/Logo-Headers.png" alt="Logo" width="194px" height="81px" className="d-inline-block align-text-top"/>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarNavDropdown">
                <ul className="navbar-nav m-auto">
                
                <li className="nav-item dropdown me-5">
                    <button className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Profil
                    </button>
                    <ul className="dropdown-menu">
                    <li><Link to="/profil/tentang-sekolah" className="dropdown-item" >Tentang Sekolah</Link></li>
                    <li><Link to="/profil/sejarah-sekolah" className="dropdown-item" >Sejarah Sekolah</Link></li>
                    <li><Link to="/profil/visi-misi" className="dropdown-item" >Visi & Misi</Link></li>
                    <li><Link to="/profil/lambang-mars" className="dropdown-item" >Lambang & Mars</Link></li>
                    <li><Link to="/profil/struktur-organisasi" className="dropdown-item" >Struktur Organisasi</Link></li>
                    <li><Link to="/profil/pendidik-tenagakependidikan" className="dropdown-item" >Pendidik & Tenaga Kependidikan</Link></li>
                    <li><Link to="/profil/peserta-didik" className="dropdown-item" >Peserta Didik</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown me-5">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Kurikulum
                    </a>
                    <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/kurikulum/kalender-pendidikan">Kalender Pendidikan</Link></li>
                    <li><Link className="dropdown-item" to="/kurikulum/jadwal-kbm">Jadwal KBM</Link></li>
                    <li><Link className="dropdown-item" to="/kurikulum/kompetensi-keahlian">Kompentensi Keahlian</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown me-5">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Humas
                    </a>
                    <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/humas/pkl">Praktik Kerja Lapangan (PKL)</Link></li>
                    <li><Link className="dropdown-item" to="/humas/bkk">Bursa Kerja Khusus (BKK)</Link></li>
                    <li><Link className="dropdown-item" to="/humas/du-di">Dunia Usaha Dunia Industri (DU/DI)</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown me-5">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Sarpras
                    </a>
                    <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/sarpras">Sarana & Prasarana</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown me-5">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Kesiswaan
                    </a>
                    <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/kesiswaan/prestasi-siswa">Prestasi</Link></li>
                    <li><Link className="dropdown-item" to="/kesiswaan/ekstrakurikuler">Ekstrakurikuler</Link></li>
                    <li><Link className="dropdown-item" to="/kesiswaan/osis">Organisasi Siswa Intra Sekolah(OSIS)</Link></li>
                    </ul>
                </li>
                <li className="nav-item dropdown me-5">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Postingan
                    </a>
                    <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/postingan/galeri">Galeri</Link></li>
                    <li><Link className="dropdown-item" to="/postingan/berita">Berita Sekolah</Link></li>
                    <li><Link className="dropdown-item" to="/postingan/agenda">Agenda Sekolah</Link></li>
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/kontak">Kontak</Link>
                </li>
                </ul>
            </div>
            </div>
        </nav>
        </>
    )
}

export default Navbar;


