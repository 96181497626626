import React, { useRef, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import "./css/style.css";

const Layout = () => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marquee = marqueeRef.current;
    if (marquee) {
      const handleMouseOver = () => marquee.stop();
      const handleMouseOut = () => marquee.start();

      marquee.addEventListener('mouseover', handleMouseOver);
      marquee.addEventListener('mouseout', handleMouseOut);

      // Cleanup event listeners on unmount
      return () => {
        marquee.removeEventListener('mouseover', handleMouseOver);
        marquee.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, []);

  return (
    <div className="app">
      <Navbar />
      <marquee ref={marqueeRef} vspace="15" style={{ fontWeight: "bold" }}>
        Alamat Email Sekolah Telah di Ubah Menjadi <Link to="mailto:smk6@smkn6jakarta.sch.id">smk6@smkn6jakarta.sch.id</Link>
      </marquee>
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
