import React, { useState,useEffect } from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";

const SISWA_URL = "/jumlah-peserta-didik";

const PesertaDidik = () =>{
    const [pesertaLakiLaki, setPesertaLakiLaki] = useState("");
    const [pesertaPerempuan, setPesertaPerempuan] = useState("");
    const [pesertaKelasX, setPesertaKelasX] = useState("");
    const [pesertaKelasXI, setPesertaKelasXI] = useState("");
    const [pesertaKelasXII, setPesertaKelasXII] = useState("");
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchSiswa();
      }, []);

      const fetchSiswa = async () => {
        try{
            const resp = await axios.get(SISWA_URL, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setData(resp?.data?.data);
            //   console.log(data.url);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="peserta-didik">
                <div className="bg">  
                        <div className="row">
                            <div className="col">
                                <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                            </div>
                        </div>
                </div>
                <div className="content">
                    <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                        <div className="row">
                            <div className="col" >
                                <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/peserta-didik" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Peserta Didik</Link>
                            <hr style={{ width:"100%" }}/>
                            </div>
                        </div>
                        <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                            <div className="col">
                                <h1 style={{ fontWeight:"bold",color:"#061745" }}>Peserta Didik</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"15px auto" }}/>
                                <div class="table-responsive" style={{ backgroundColor:"#061745",color:"#fff",padding:"10px",marginTop:"50px" }}>
                                    <p style={{ fontSize:"30px",fontWeight:"bold",padding:"10px" }}>Jenis Kelamin</p>
                                    <table class="table align-middle">
                                        <tbody>
                                        <tr>
                                            <td>Laki-Laki</td>
                                            <td>{data?data.laki_laki:"000"}</td>
                                        </tr>
                                        <tr>
                                            <td>Perempuan</td>
                                            <td>{data?data.perempuan:"000"}</td>
                                        </tr>
                                        <tr style={{ fontWeight:"bold" }}>
                                            <td>Jumlah</td>
                                            <td>{parseInt(data.laki_laki,10) + parseInt(data.perempuan,10)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive" style={{ backgroundColor:"#FFB606",color:"#fff",padding:"10px",marginTop:"50px" }}>
                                    <p style={{ fontSize:"30px",fontWeight:"bold",padding:"10px" }}>Tingkatan Kelas</p>
                                    <table class="table align-middle">
                                        <tbody>
                                        <tr>
                                            <td>Kelas X</td>
                                            <td>{data?data.kelas_x:"000"}</td>
                                        </tr>
                                        <tr>
                                            <td>Kelas XI</td>
                                            <td>{data?data.kelas_xi:"000"}</td>
                                        </tr>
                                        <tr>
                                            <td>Kelas XII</td>
                                            <td>{data?data.kelas_xii:"000"}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a href={data?data.url:"#"}>
                                <button className="button mt-5" id="download">
                                Download Data Peserta Didik
                                </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default PesertaDidik;