import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const OSIS_URL = "/osis";

const OsisAdmin = () => {
  const [gambarOsis,setGambarOsis] = useState("")
  const [image,setImage] = useState("")
  const [visiOsis,setVisiOsis] = useState("")
  const [misiOsis,setMisiOsis] = useState("")
  const token = getCookie("_auth");

  // Mengambil nilai dari database
  useEffect(() => {
    const fetchData = async () => {
    try {
        const response = await axios.get(OSIS_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
        });
        const { gambar,visi,misi } = response.data?.data;
        setGambarOsis(gambar);
        setImage(response.data?.data?.image)
        setVisiOsis(visi);
        setMisiOsis(misi);


    } catch (error) {
        console.log(error);
    }
    };

    fetchData();
}, []);
  


const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
    formData.append("file", gambarOsis);
    formData.append("visi", visiOsis);
    formData.append("misi", misiOsis);


  try{
      const resp = await axios.post(`${OSIS_URL}?_method=PATCH`, formData, {
          headers: { 
            "Accept" : "appliaction/json",
            "Authorization" : `Bearer ${token}`
         },
          // withCredentials:true
        });
        Toast.fire({
          icon: 'success',
          title: 'Berhasil Menambahkan Data Osis'
        });
        window.location.reload();
  }catch(error){
      Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message
        });
        console.log(error?.response?.data);
  }
};

  return (
    <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col ">
                            <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>Ubah Osis</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <form onSubmit={handleSubmit}>
                            {image && (
                            <div className="mt-4">
                                <label className="form-label">Gambar saat ini:</label><br/>
                                <img src={'https://smkn6jakarta.sch.id/app/storage/osis/'+image} alt="Gambar saat ini" className="img-fluid" />
                            </div>
                            )}
                            <div className="form-group mt-4">
                            <label htmlFor="GambarOsis">Gambar OSIS :</label>
                            <input
                                type="file"
                                className="form-control"
                                id="GambarOsis"
                                accept="image/*"
                                name="GambarOsis"
                                // value={gambarOsis}
                                onChange={(e) => setGambarOsis(e.target.files[0])}
                            />
                            <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div> 
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="visiOsis">Visi OSIS :</label>
                            <input
                                type="text"
                                className="form-control"
                                id="visiOsis"
                                value={visiOsis}
                                onChange={(e) => setVisiOsis(e.target.value)}
                            />
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="misiOsis">Misi OSIS</label>
                            <input
                                type="text"
                                className="form-control"
                                id="misiOsis"
                                value={misiOsis}
                                onChange={(e) => setMisiOsis(e.target.value)}
                            />
                            </div>
                            <div className="form-group">
                            <button type="submit" className="btn btn-primary mt-4" style={{ background:"#061745" }}>
                                Submit
                            </button>
                            </div>
                        </form>
                            
                                                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

  );
};

export default OsisAdmin;
