import React, { useState,useEffect,useRef } from "react";
import "../css/tentangSekolah.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";

const JMLGURU_URL = "/jumlah-pendidik";
const GURU_URL = "/data-pendidik";
const TENDIK_URL = "/jumlah-tenaga-kependidikan";


const PendidikTenagaKependidikan = () =>{
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate(); // Mendapatkan fungsi navigate

    const [data, setData] = useState([]);
    const [dataGuru, setDataGuru] = useState([]);
    const [dataTendik, setDataTendik] = useState([]);

    useEffect(() => {
        fetchGuru();
      }, []);

      const fetchGuru = async () => {
        try {
          // Menggunakan Promise.all untuk menggabungkan tiga permintaan
          const [strukturResp, guruResp, tendikResp] = await Promise.all([
            axios.get(JMLGURU_URL, {
              headers: {
                "Accept": "application/json",
              },
            }),
            axios.get(GURU_URL, {
              headers: {
                "Accept": "application/json",
              },
            }),
            axios.get(TENDIK_URL, {
              headers: {
                "Accept": "application/json",
              },
            }),
          ]);
      
          // Mengatur data struktur organisasi, data guru, dan data tendik ke dalam state
          setData(strukturResp?.data?.data);
          setDataGuru(guruResp?.data?.data);
          setDataTendik(tendikResp.data?.data);
        } catch (error) {
          console.log(error?.response?.data);
        }
      };
      

    // console.log(data);
    // Konfigurasi halaman
  const PendidiksPerPage = 10;
  const totalPages = Math.ceil(dataGuru.length / PendidiksPerPage);
  const indexOfLastPendidiks = currentPage * PendidiksPerPage;
  const indexOfFirstPendidiks = indexOfLastPendidiks - PendidiksPerPage;
  const currentPendidiks = dataGuru.slice(indexOfFirstPendidiks, indexOfLastPendidiks);

  // Fungsi untuk pindah ke halaman berikutnya
  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Fungsi untuk pindah ke halaman sebelumnya
  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Fungsi untuk pindah ke halaman tertentu
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


    return(
        <> 
        <div className="container-fluid p-0">
            <div className="pendidik-tenagakependidikan">
                <div className="bg">  
                        <div className="row">
                            <div className="col">
                                <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                            </div>
                        </div>
                </div>
                <div className="content">
                    <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                        <div className="row">
                            <div className="col" >
                                <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/profil/pendidik-tenagakependidikan" style={{ color:"#000000" }}>Profil <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Pendidik & Tenga Kependidikan</Link>
                            <hr style={{ width:"100%" }}/>
                            </div>
                        </div>
                        <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                            <div className="col">
                                <h1 style={{ fontWeight:"bold",color:"#061745" }}>Pendidik (Guru)</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"15px auto" }}/>
                                <div class="table-responsive" style={{ backgroundColor:"#061745",color:"#fff",padding:"10px",marginTop:"50px" }}>
                                    <p style={{ fontSize:"30px",fontWeight:"bold",padding:"10px" }}>Jumlah Pendidik (Guru)</p>
                                    <table class="table align-middle">
                                        <tbody>
                                        <tr>
                                            <td>Pegawai Negeri Sipil (PNS)</td>
                                            <td>{data.pns}</td>
                                        </tr>
                                        <tr>
                                            <td>Calon Pegawai Negeri Sipil (CPNS)</td>
                                            <td>{data.cpns}</td>
                                        </tr>
                                        <tr>
                                            <td>Tenaga Kontrak Kerja Individu</td>
                                            <td>{data.kontrak}</td>
                                        </tr>
                                        <tr>
                                            <td>Guru Honorer</td>
                                            <td>{data.honorer}</td>
                                        </tr>
                                        <tr style={{ fontWeight:"bold" }}>
                                            <td>Jumlah</td>
                                            <td>{parseInt(data.pns,10) + parseInt(data.cpns,10) + parseInt(data.kontrak,10) + parseInt(data.honorer,10)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                                {/* nama guru */}
                                <table class="table-responsive mt-5">
                                    <thead>
                                        <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Nama Lengkap</th>
                                        <th scope="col">Mata Pelajaran</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {currentPendidiks.map((pendidik,index) => (
                                        <tr key={pendidik.id}>
                                        <th scope="row">{index + indexOfFirstPendidiks + 1}</th>
                                        <td>{pendidik.name}</td>
                                        <td>{pendidik.kompetensi.name}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {/* Tombol navigasi halaman */}
                                <div className="row mt-3">
                                        <div className="col text-center">
                                            {currentPage > 1 && (
                                            <button className="btn" onClick={goToPreviousPage}>
                                                <i class="bi bi-caret-left-fill"></i>
                                            </button>
                                            )}
                                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                            <span
                                                key={pageNumber}
                                                className={`page-number ${pageNumber === currentPage ? "active" : ""}`}
                                                onClick={() => goToPage(pageNumber)}
                                            >
                                                {pageNumber}
                                            </span>
                                            ))}
                                            {currentPage < totalPages && (
                                            <button className="btn" onClick={goToNextPage}>
                                                <i class="bi bi-caret-right-fill"></i>
                                            </button>
                                            )}
                                        </div>
                                </div>                                
                                <h1 style={{ fontWeight:"bold",color:"#061745",marginTop:"150px" }}>Tenaga Kependidikan</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"15px auto" }}/>
                                <div class="table-responsive" style={{ backgroundColor:"#FFB606",color:"#fff",padding:"10px",marginTop:"50px" }}>
                                    <p style={{ fontSize:"30px",fontWeight:"bold",padding:"10px" }}>Jumlah Tenaga Kependidikan</p>
                                    <table class="table align-middle">
                                        <tbody>
                                        <tr>
                                            <td>Pegawai Negeri Sipil (PNS)</td>
                                            <td>{dataTendik.pns}</td>
                                        </tr>
                                        <tr>
                                            <td>Calon Pegawai Negeri Sipil (CPNS)</td>
                                            <td>{dataTendik.cpns}</td>
                                        </tr>
                                        <tr>
                                            <td>Tenaga Kontrak Kerja Individu</td>
                                            <td>{dataTendik.kontrak}</td>
                                        </tr>
                                        <tr>
                                            <td>Guru Honorer</td>
                                            <td>{dataTendik.honorer}</td>
                                        </tr>
                                        <tr style={{ fontWeight:"bold" }}>
                                            <td>Jumlah</td>
                                            <td>{parseInt(dataTendik.pns,10) + parseInt(dataTendik.cpns,10) + parseInt(dataTendik.kontrak,10) + parseInt(dataTendik.honorer,10)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default PendidikTenagaKependidikan;