import React,{useState, useEffect, useRef,} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../../../api/axios";
import DOMPurify from 'dompurify';

const JURUSAN_URL = "/kompetensi-keahlian";
const GURU_URL = "/data-pendidik";
const APP_URL = "https://smkn6jakarta.sch.id/app/storage"

const KompetensiKeahlianSlash = () =>{
    const { id } = useParams();
    const userRef = useRef();
    const [data,setData] =useState([]);
    const [dataGuru,setDataGuru] =useState([]);
    const [guruByKompetensiID, setGuruByKompetensiID] = useState([]);

    useEffect(() => {
        if (userRef.current) {
          userRef.current.focus();
        }
        if (id) {
            // Fetch existing data based on ID
            fetchJurusan();
          }
      }, [id]);

      const fetchJurusan = async () => {
        try{
            const resp = await axios.get(`${JURUSAN_URL}/${id}`, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setData(resp.data?.data);
            //   console.log(resp.data?.data);
        }catch (error) {
            console.log(error?.response?.data);
          }
      }; 


      useEffect(() => {
        const fetchGuruByKompetensiID = async () => {
          try {
            const response = await axios.get(`${GURU_URL}`, {
              headers: {
                "Accept": "application/json",
              },
              // withCredentials:true
            });
            const guruData = response?.data?.data;
            const filteredGuru = guruData.filter((guru) => guru.kompetensi_id === id);
            setGuruByKompetensiID(filteredGuru);
          } catch (error) {
            console.log(error?.response?.data);
          }
        };
    
        fetchGuruByKompetensiID();
      }, [id]);

      const splitByComa = (isi) => {
        const splittedData = isi ? isi.split(";") : [];
        return splittedData;
      };

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/kurikulum/kompetensi-keahlian/desain-komunikasi-visual" style={{ color:"#000000" }}>Kurikulum <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Kompetensi-Keahlian <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> {data.name}</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center mt-4" style={{ padding:"0px 40px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>{data.name}</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <div className="row">
                                <div className="col">
                                <img src={`${APP_URL}/${JURUSAN_URL}/`+data.image} className="d-block w-100" alt="..." />
                                    {/* <div id="carouselExampleIndicators" className="carousel slide">
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        </div>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                            <img src="/img/Gambar.png" className="d-block w-100" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}/>
                                            </div>
                                            <div className="carousel-item">
                                            <img src="/img/logosmk6.jpg" className="d-block w-100" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}/>
                                            </div>
                                            <div className="carousel-item">
                                            <img src="/img/Gambar.png" className="d-block w-100" alt="" style={{ maxWidth:"100%", height:"auto", marginTop:"20px",width:"1150px" }}/>
                                            </div>
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col-md-8">
                                <div className="d-flex justify-content-between">
                                <p style={{ textAlign: "justify", marginRight: "20px" }} dangerouslySetInnerHTML={{ __html :DOMPurify.sanitize(data.description) }}>
                                    
                                </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div id="carouselExampleIndicators2" className="carousel slide" data-bs-ride="carousel">
                                
                                <div className="carousel-inner">
                                    {guruByKompetensiID.map((guruData,index)=>(
                                    <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={guruData.id}>
                                        <img src={`${APP_URL}/${GURU_URL}/`+guruData.image} className="img-fluid" alt="foto guru" style={{ height:"250px",width:"100%",objectFit:"contain" }} />
                                        <div class="carousel-caption d-none d-md-block">
                                                    <h6 className="p-2 rounded" style={{ background:"#051133",marginBottom:"-20px" }}>{guruData.name}</h6> 
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                                </div>
                            </div>
                            </div>

                            <div className="row mt-5" style={{ backgroundColor:"#061745", padding:"20px 20px",color:"#fff" }}>
                                <div className="col text-start">
                                <h1 style={{ fontWeight:"bold"}}>Materi Keahlian</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px 0px" }}/>   
                                <ul style={{ fontSize:"20px" }}>
                                    {splitByComa(data.subject).map((materi,index)=>(
                                        <li>{materi}</li>
                                    ))}
                                </ul>
                                </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col" style={{textAlign:"justify"}}>
                                <h1 style={{ fontWeight:"bold"}}>Profil Kemampuan Tamatan</h1>
                                <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px 0px" }}/>   
                                <ul style={{ fontSize:"20px" }}>
                                {splitByComa(data.graduate).map((lulusan,index)=>(
                                    <li>{lulusan}</li>
                                ))}
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default KompetensiKeahlianSlash;