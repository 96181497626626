import React,{useState, useEffect, useRef,} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getCookie } from "../../../utils/cookieUtils";

const BERITA_URL = "/berita-sekolah"

const FormBerita = () => {
    const { id } = useParams();
    const userRef = useRef();
    const [title, setTitle] = useState("");
    const [file, setFile] = useState("");
    const [image, setImage] = useState("");
    const [quillContent, setQuillContent] = useState("");

    const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
    const token = getCookie("_auth");
    const navigate = useNavigate();

    const location = useLocation();
    const { isFormDisabled } = location.state || {};


    useEffect(() => {
        if (userRef.current) {
          userRef.current.focus();
        }
        if (id) {
            // Fetch existing data based on ID
            fetchBerita();
          }
      }, [id]);

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      const fetchBerita = async () => {
        try {
          const resp = await axios.get(`${BERITA_URL}/${id}`, {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Accept": "appliaction/json",
            },
          });
        //   console.log(resp.data);
          setTitle(resp.data?.data?.title);
          setImage(resp.data?.data?.image);
          setDate(resp.data?.data?.date);
          setQuillContent(resp.data?.data?.content);
          // console.log(resp.data?.data?.image);
          
        } catch (error) {
          console.log(error?.response?.data);
        }
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
      const formData = new FormData();
      formData.append("title", title);
      formData.append("date", date);
      formData.append("file", file);
      formData.append("content", quillContent);

        try {
          if(id){
            // Edit
      const resp = await axios.post(`${BERITA_URL}/${id}?_method=PATCH`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
          "Accept": "appliaction/json",
          "Authorization": `Bearer ${token}`
        },
      });
      Toast.fire({
        icon: 'success',
        title: 'Berhasil Mengedit Berita'
      });
      navigate("/admin/posting-berita", { replace: true });
    } 
    // Post
    else {
      const resp = await axios.post(BERITA_URL, formData, {
        headers: { 
          "Content-type": "multipart/form-data",
          "Accept" : "appliaction/json",
          "Authorization" : `Bearer ${token}`
       },
        // withCredentials:true
      });
      
      setTitle("");
      setFile("");
      setDate("");
      setQuillContent("");
      Toast.fire({
        icon: 'success',
        title: 'Berhasil Menambahkan Berita'
      });
      navigate("/admin/posting-berita", { replace: true });
    }
      } catch (error) {
          Toast.fire({
            icon: 'error',
            title: error?.response?.data?.message
          });
          console.log(quillContent);
          console.log(error?.response?.data);
        }
      };

  return (
    <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col ">
                            <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>{id ? "Edit Berita" : "Tambah Berita"}</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            
                            <form onSubmit={handleSubmit}>
                            <div className="form-group">
                            <label htmlFor="judulBerita">Judul Berita :</label>
                            <input
                                type="text"
                                className="form-control"
                                id="judulBerita"
                                disabled={isFormDisabled}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            </div>
                            
                            <div className="form-group mt-3">
                                <label for="UploadDate" className="form-label">Waktu, Tanggal</label>
                                <input 
                                type="date" 
                                className="form-control" 
                                id="UploadDate"
                                disabled={isFormDisabled} 
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                                {date && (
                                <div className="">
                                    <label className="form-label">Tanggal saat ini: {date}</label>
                                </div>
                                )}                                
                            {image && (
                            <div className="mt-3">
                                <label className="form-label">Gambar saat ini:</label>
                                <img src={'https://smkn6jakarta.sch.id/app/storage/berita-sekolah/'+image} alt="Gambar saat ini" className="img-fluid" />
                            </div>
                            )}
                            <div className="form-group mt-4">
                            <label htmlFor="GambarBerita">Gambar Berita :</label>
                            <input
                                type="file"
                                className="form-control"
                                id="GambarBerita"
                                // value={file}
                                accept="image/*"
                                disabled={isFormDisabled}
                                name="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                            <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div> 
                            </div>

                            <div className="form-group mt-4">
                            <label htmlFor="DeskripsiBerita">Konten Berita :</label>
                            <ReactQuill 
                             value={quillContent}
                             disabled={isFormDisabled}
                             onChange={setQuillContent}
                            />
                            </div>
                            
                            <div className="form-group">
                            <button type="submit" className="btn btn-primary mt-3" disabled={id ? true : false}>Submit{" "}</button>
                            <button type="submit" className="btn btn-warning ms-3 mt-3" disabled={id ? false : true}>Edit{" "}</button>
                            <Link to="/admin/posting-berita" className="btn btn-primary mt-3 ms-3" style={{ background:"#061745" }}>Kembali</Link>
                            </div>
                        </form>
                            
                                                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

  );
};

export default FormBerita;
