import React,{useState, useEffect} from "react";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const SISWA_URL = "/jumlah-peserta-didik";

const UbahPesertaDidik = () =>{


    const [pesertaLakiLaki, setPesertaLakiLaki] = useState("");
    const [pesertaPerempuan, setPesertaPerempuan] = useState("");
    const [pesertaKelasX, setPesertaKelasX] = useState("");
    const [pesertaKelasXI, setPesertaKelasXI] = useState("");
    const [pesertaKelasXII, setPesertaKelasXII] = useState("");
    const [url, setUrl] = useState("");
    
    const token = getCookie("_auth");

    // Mengambil nilai dari database
    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.get(SISWA_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            }
            });
            const { laki_laki, perempuan, kelas_x, kelas_xi, kelas_xii, url } = response.data?.data;
            setPesertaLakiLaki(laki_laki);
            setPesertaPerempuan(perempuan);
            setPesertaKelasX(kelas_x);
            setPesertaKelasXI(kelas_xi);
            setPesertaKelasXII(kelas_xii);
            setUrl(url);
        } catch (error) {
            console.log(error);
        }
        };
    
        fetchData();
    }, []);
      

  
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
        formData.append("laki_laki", pesertaLakiLaki);
        formData.append("perempuan", pesertaPerempuan);
        formData.append("kelas_x", pesertaKelasX);
        formData.append("kelas_xi", pesertaKelasXI);
        formData.append("kelas_xii", pesertaKelasXII);
        formData.append("url", url);
  
      try{
          const resp = await axios.post(`${SISWA_URL}?_method=PATCH`, formData, {
              headers: { 
                "Accept" : "appliaction/json",
                "Authorization" : `Bearer ${token}`
             },
              // withCredentials:true
            });
            Toast.fire({
              icon: 'success',
              title: 'Berhasil Menambahkan Jumlah Peserta Didik'
            });
      }catch(error){
          Toast.fire({
              icon: 'error',
              title: error?.response?.data?.message
            });
            console.log(error?.response?.data);
      }
    };

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5 text-center" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Ubah Peserta Didik</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            
                            <form className="text-start" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Peserta Didik Laki-laki</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                id="pesertaLakiLaki"
                                name="pesertaLakiLaki"
                                // placeholder={data.laki_laki} 
                                value={pesertaLakiLaki}
                                onChange={(e) => setPesertaLakiLaki(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Peserta Didik Perempuan</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                id="pesertaPerempuan"
                                name="pesertaPerempuan" 
                                value={pesertaPerempuan}
                                onChange={(e) => setPesertaPerempuan(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Peserta Didik Kelas X</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                id="pesertaKelasX"
                                name="pesertaKelasX" 
                                value={pesertaKelasX}
                                onChange={(e) => setPesertaKelasX(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Peserta Didik Kelas XI</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                id="pesertaKelasXI"
                                name="pesertaKelasXI"
                                value={pesertaKelasXI} 
                                onChange={(e) => setPesertaKelasXI(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Jumlah Peserta Didik Kelas XII</label>
                                <input 
                                type="number" 
                                className="form-control" 
                                id="pesertaKelasXII"
                                name="pesertaKelasXII" 
                                value={pesertaKelasXII}
                                onChange={(e) => setPesertaKelasXII(e.target.value)}
                                />                                
                            </div>
                            <div className="mb-3">
                                <label for="UploadLink" className="form-label">Masukkan Link Drive</label>
                                <input 
                                type="url" 
                                className="form-control" 
                                id="url"
                                name="url" 
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                />                                
                            </div>
                            <button type="submit" className="btn btn-primary mt-3" style={{ background:"#061745",padding:"10px 70px"  }}>Submit</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default UbahPesertaDidik;