import React, { useState,useEffect } from "react";
import "../css/tentangSekolah.css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";

const OSIS_URL = "/osis";

const Osis = () =>{
    const [data,setData] = useState("");
    const [misi,setMisi] = useState("");
    
    // const misiArr = misi
    

    useEffect(() => {
        fetchOsis();
      }, []);

      const fetchOsis = async () => {
        try{
            const resp = await axios.get(OSIS_URL, {
                headers: { 
                //   "Authorization" : `Bearer ${accessToken}`
                "Accept": "application/json"
               },
                // withCredentials:true
              });
              setData(resp.data?.data);
              setMisi(resp.data?.data?.misi)
            //   console.log(misi);

        }catch (error) {
            console.log(error?.response?.data);
          }
      };

      const misiArr = misi.split(";");
      console.log(misiArr);

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/kesiswaan/osis" style={{ color:"#000000" }}>Kesiswaan <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> OSIS</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>Organisasi Siswa Intra Sekolah (OSIS)</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px",margin:"auto" }}/>
                            <img src={'https://smkn6jakarta.sch.id/app/storage/osis/'+data.image} alt="" style={{ maxWidth:"100%", height:"auto", margin:"40px 0px",width:"1150px" }}></img>
                            
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Visi</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <p style={{ textAlign:"justify",fontSize:"20px" }}>{data.visi}</p>
                            <h1 style={{ fontWeight:"bold",color:"#061745",marginTop:"50px" }}>Misi</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px" }}/>
                            <ol class="list-group list-group-numbered list-group-flush" style={{ fontSize:"20px",textAlign:"justify" }}>
                            {misiArr.map((item,index) =>(
                                <li class="list-group-item">{item}</li>
                            ))}   

                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default Osis;