import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const STRUKTUR_URL = "/struktur-organisasi";
const APP_URL = "https://smkn6jakarta.sch.id/app/storage/struktur-organisasi/"


const UbahStrukturOrganisasi = () =>{

    const token = getCookie("_auth");
    const [gambar1,setGambar1] = useState("");
    const [gambar2,setGambar2] = useState("");
    const [image1,setImage1] = useState("");
    const [image2,setImage2] = useState("");

   // Mengambil nilai dari database
   useEffect(() => {
    const fetchData = async () => {
    try {
        const response = await axios.get(STRUKTUR_URL, {
        headers: {
            Authorization: `Bearer ${token}`
        }
        });
        setImage1(response.data?.data?.image)
        setImage2(response.data?.data?.desc_image)
    } catch (error) {
        console.log(error);
    }
    };

    fetchData();
}, []);
  


const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
    formData.append("file1", gambar1);
    formData.append("file2", gambar2);

  try{
      const resp = await axios.post(`${STRUKTUR_URL}?_method=PATCH`, formData, {
          headers: { 
            "Accept" : "appliaction/json",
            "Authorization" : `Bearer ${token}`
         },
          // withCredentials:true
        });
        Toast.fire({
          icon: 'success',
          title: 'Berhasil Menambahkan Data Struktur Organisasi'
        });
        window.location.reload();
  }catch(error){
      Toast.fire({
          icon: 'error',
          title: error?.response?.data?.message
        });
        console.log(error?.response?.data);
  }
};


    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow " style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5 text-center " style={{ padding:"10px 80px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Ubah Struktur Organisasi</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            
                            <div className="row">
                                <div className="col g-4">
                                {image1 && (
                            <div className="mt-4 border p-3">
                                <label className="form-label">Gambar saat ini:</label><br/>
                                <img src={APP_URL+image1} alt="Gambar saat ini" className="img-fluid" />
                            </div>
                            )}
                                </div>
                                <div className="col g-4">
                                {image2 && (
                            <div className="mt-4 border p-3">
                                <label className="form-label">Gambar Penjelasan Saat Ini:</label><br/>
                                <img src={APP_URL+image2} alt="Gambar saat ini" className="img-fluid" />
                            </div>
                            )}
                                </div>
                            </div>
                            
                            <form className="text-start" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col g-4">
                                <div className="mb-3">
                                    <label for="UploadLink" className="form-label">Masukkan Gambar Struktur Organisasi</label>
                                    <input 
                                    type="file" 
                                    className="form-control" 
                                    aria-describedby="emailHelp"
                                    id="Gambar"
                                    name="Gambar"
                                    accept="image/*"
                                    onChange={(e) => setGambar1(e.target.files[0])}
                                    />
                                    <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div>                                 
                                </div>
                                </div>
                                <div className="col g-4">
                                    <div className="mb-3">
                                        <label for="UploadLink" className="form-label">Masukkan Gambar Penjelasan</label>
                                        <input 
                                        type="file" 
                                        className="form-control" 
                                        aria-describedby="emailHelp"
                                        id="DeskripsiGambar"
                                        name="DeskripsiGambar"
                                        accept="image/*"
                                        onChange={(e) => setGambar2(e.target.files[0])}
                                        />
                                        <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div>                                 
                                    </div>
                               
                                </div>
                            </div>
                            
                            
                           
                            
                            <button type="submit" className="btn btn-primary " style={{ background:"#061745",padding:"10px 70px" }}>Submit</button>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default UbahStrukturOrganisasi;