import React from "react"
import { Link } from "react-router-dom";


const Footer =()=>{
    const currentYear = new Date().getFullYear();
    return(
        <>
          <footer>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 text-white">
                  <div className="d-flex align-items-center">
                    <img src="/img/img-smkn6/Logo-SMKN6JKT-HD.png" alt="" className="mr-3 me-3" style={{ width: "100px", height: "auto" }} />
                    <div>
                      <h3>SMK Negeri 6 Jakarta</h3>
                      <p style={{ fontSize: "small" }}>Jl. Prof. Joko Sutono SH No.2A, RT.1/RW.2,<br/> Melawai, Kec. Kebayoran  Baru,<br/> Jakarta Selatan - Indonesia 12160.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-white" style={{ fontSize:"small" }}>
                  <p><b>Telepon</b><br/><Link to="tel:0217208718">(021) 7208718</Link></p>
                  <p><b>Email</b><br/><Link to="mailto:smk6@smkn6jakarta.sch.id">smk6@smkn6jakarta.sch.id</Link></p>
                </div>
                <div className="col-md-4 text-white">
                  <a href="https://www.instagram.com/smkn6jkt/" target="_blank"><i className="bi bi-instagram text-white mx-2" style={{ fontSize:"34px;" }} id="socmed"></i></a>
                  <a href="https://www.tiktok.com/@smkn6jkt" target="_blank"><i className="bi bi-tiktok text-white mx-2" style={{ fontSize:"34px;" }} id="socmed"></i></a>
                  <a href="https://www.youtube.com/@smknegeri6jakarta" target="_blank"><i className="bi bi-youtube text-white mx-2" style={{ fontSize:"34px;" }} id="socmed"></i></a>
                </div>
              </div>
              <hr style={{ color: "#fff" }}/>
              <div className="row text-center text-white">
                <div className="col">
                  © Copyright { currentYear }.
                  SMK Negeri 6 Jakarta
                </div>
              </div>
            </div>
          </footer>
        </>
    )
}

export default Footer;
