import React, { useState,useEffect } from 'react';
import { Link,Outlet,useNavigate } from "react-router-dom";
import "../css/admin.css"
import Swal from 'sweetalert2';
import axios from "../../api/axios";
import { useSignOut } from 'react-auth-kit'


const ME_URL = "/me";

const AdminLayout = () => {
 

  const navigate = useNavigate();
  const logout = useSignOut();
  
  
  

  const signOut = async () => {
    logout();
    Toast.fire({
      icon: 'success',
      title: 'Berhasil Logout'
    })
    navigate('/login');
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  const [data, setData] = useState([]);

    useEffect(() => {
        fetchME();
      }, []);


      

      const fetchME = async () => {
        try{
            const resp = await axios.get(ME_URL, {
                headers: { 
                    "Accept": "appliaction/json",
                    
               },
                // withCredentials:true
              });
              setData(resp.data);
              // console.log(resp.data);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };

  
   


  // Kode tata letak dan navigasi admin di sini

  return (
    <>
    <div className="admin">
      
      {/* Navigasi admin */}
      
      <nav className="navbar" style={{ backgroundColor:"#061745" }}>
        <div className="container">
            <Link className="navbar-brand" to="/admin">
                <div className="profil text-white" style={{ fontSize:"30px" }}>
                    <i className="bi bi-person-circle"></i> {data.id ?  data.username:"admin"}
                </div>
            </Link>
            <Link to="">
            <span className="navbar-text" style={{ fontSize:"25px" }}>
                <button style={{ backgroundColor:"#061745" }} onClick={signOut}><i className="bi bi-box-arrow-right text-white"></i></button>                
            </span>
            </Link>
        </div>
      </nav>

    <div className="row">
    {/* Navigasi kiri */}
    <nav className="col-md-2 d-none d-md-block bg-light sidebar">
        
          <div className="sidebar-sticky">
            <ul className="nav flex-column">
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   Profil Sekolah
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">                    
                  <Link className="nav-link" to="/admin/ubah-struktur-organisasi">Ubah Struktur Organisasi</Link>
                  <hr/>
                  <Link className="nav-link" to="/admin/ubah-peserta-didik">Ubah Jumlah Peserta Didik</Link><hr/>
                  <Link className="nav-link" to="/admin/ubah-pendidik">Ubah Jumlah Pendidik</Link><hr/>
                  <Link className="nav-link" to="/admin/ubah-tenaga-kependidikan">Ubah Jumlah Tenaga Kependidikan</Link>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Kurikulum
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                  <Link className="nav-link" to="/admin/kalender-pendidikan-admin">Ubah Kalender Pendidikan</Link><hr/>
                  <Link className="nav-link" to="/admin/jadwal-kbm-admin">Ubah Jadwal KBM</Link><hr/>
                  <Link className="nav-link" to="/admin/kompetensi-keahlian-admin">Kompetensi Keahlian</Link>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Sarpras
                  </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                  <Link className="nav-link" to="/admin/ubah-sarpras">Ubah Sarpras</Link><hr/>
                  
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    Kesiswaan
                  </button>
                </h2>
                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                  <Link className="nav-link" to="/admin/posting-prestasi">Ubah Prestasi</Link><hr/>
                  <Link className="nav-link" to="/admin/ubah-ekstrakurikuler">Ubah Ekstrakurikuler</Link><hr/>
                  <Link className="nav-link" to="/admin/osis-admin">Ubah Data OSIS</Link>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    Postingan
                  </button>
                </h2>
                <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                  <Link className="nav-link" to="/admin/galeri-admin">Ubah Galeri</Link><hr/>
                  <Link className="nav-link" to="/admin/posting-berita">Ubah Berita Sekolah</Link><hr/>
                  <Link className="nav-link" to="/admin/posting-agenda">Ubah Agenda Sekolah</Link>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    Guru
                  </button>
                </h2>
                <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                  <Link className="nav-link" to="/admin/guru-admin">Ubah Data Guru</Link>
                  </div>
                </div>
              </div>
            </div>
            </ul>
          </div>
    </nav>
      {/* Konten admin */}
    <div className="col-md-10 ml-sm-auto">
      <Outlet />
    </div>
    </div>

    </div>
    </>
  );
};

export default AdminLayout;
