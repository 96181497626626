import './App.css';

import React, { Component,useState,useContext } from "react";
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import { RequireAuth } from 'react-auth-kit'
import Layout from './component/Layout';
import Home from './component/Home';
import TentangSekolah from './component/Profil/TentangSekolah';
import SejarahSekolah from './component/Profil/SejarahSekolah';
import VisiMisi from './component/Profil/VisiMisi';
import LambangMars from './component/Profil/LambangMars';
import StrukturOrganisasi from './component/Profil/StrukturOrganisasi';
import PendidikTenagaKependidikan from './component/Profil/PendidikTenagaKependidikan';
import PesertaDidik from './component/Profil/PesertaDidik';
import KalenderPendidikan from './component/Kurikulum/KalenderPendidikan';
import JadwalKBM from './component/Kurikulum/JadwalKbm';
import KompetensiKeahlian from './component/Kurikulum/KompetensiKeahlian/KompetensiKeahlian';
import DesainKomunikasiVisual from './component/Kurikulum/KompetensiKeahlian/DesainKomunikasiVisual';
import Login from './component/Login';
import AdminLayout from './component/Admin/AdminLayout';
import DashboardAdmin from './component/Admin/DashboardAdmin';
import UbahPesertaDidik from './component/Admin/ProfilAdmin/UbahPesertaDidik';
import Pkl from './component/Humas/Pkl';
import Bkk from './component/Humas/Bkk';
import DuDi from './component/Humas/DuDi';
import SarPras from './component/Sarpras/SarPras';
import FasilitasSekolah from './component/Sarpras/FasilitasSekolah';
import PrestasiSiswa from './component/Kesiswaan/PrestasiSiswa';
import Ekstrakurikuler from './component/Kesiswaan/Ekstrakurikuler';
import Osis from './component/Kesiswaan/Osis';
import Kontak from './component/Kontak';
import Galeri from './component/Postingan/Galeri';
import PrestasiDetail from './component/Kesiswaan/PrestasiDetail';
import Berita from './component/Postingan/Berita';
import DetailBerita from './component/Postingan/DetailBerita';
import Agenda from './component/Postingan/Agenda';
import DetailAgenda from './component/Postingan/DetaiAgenda';
import UbahStrukturOrganisasi from './component/Admin/ProfilAdmin/UbahStrukturOrganisasi';
import UbahPendidik from './component/Admin/ProfilAdmin/UbahPendidik';
import UbahTenagaKependidikan from './component/Admin/ProfilAdmin/UbahTenagaKependidikan';
import JadwalKbmAdmin from './component/Admin/KurikulumAdmin/JadwalKbmAdmin';
import KalenderPendidikanAdmin from './component/Admin/KurikulumAdmin/KalenderPendidikanAdmin';
import KompetensiKeahlianAdmin from './component/Admin/KurikulumAdmin/KompetensiKeahlianAdmin';
import UbahSarpras from './component/Admin/SarPrasAdmin/UbahSarpras';
import UbahFasilitas from './component/Admin/SarPrasAdmin/UbahFasilitas';
import GaleriAdmin from './component/Admin/PostinganAdmin/GaleriAdmin';
import GuruAdmin from './component/Admin/SarPrasAdmin/GuruAdmin';
import PostingBerita from './component/Admin/PostinganAdmin/PostingBerita';
import PostingAgenda from './component/Admin/PostinganAdmin/PostingAgenda';
import PostingPrestasi from './component/Admin/KesiswaanAdmin/PostingPrestasi';
import UbahEkstrakurikuler from './component/Admin/KesiswaanAdmin/UbahEkstrakurikuler';
import EkstrakurikulerDetail from './component/Admin/KesiswaanAdmin/EkstrakurikulerDetail';
import KompetensiKeahlianForm from './component/Admin/KurikulumAdmin/KompetnsiKeahlianForm';
import DetailPrestasi from './component/Admin/KesiswaanAdmin/DetailPrestasi';
import FormBerita from './component/Admin/PostinganAdmin/FormBerita';
import FormAgenda from './component/Admin/PostinganAdmin/FormAgenda';
import FormGuru from './component/Admin/SarPrasAdmin/FormGuru';
import FormGaleri from './component/Admin/PostinganAdmin/FormGaleri';
import FormSarpras from './component/Admin/SarPrasAdmin/FormSarpras';
import FormFasilitas from './component/Admin/SarPrasAdmin/FormFasilitas';
import OsisAdmin from './component/Admin/KesiswaanAdmin/OsisAdmin';
import { AuthProvider } from 'react-auth-kit'
import AuthGuard from './auth/AuthGuard';
import KompetensiKeahlianSlash from './component/Kurikulum/KompetensiKeahlian/KompetensiKeahlianDetail';

const App =()=> {
    return(
      <>
      <AuthProvider authType = {'cookie'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={window.location.protocol === "https:"}>
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path='/profil/tentang-sekolah' element={<TentangSekolah/>}/>
            <Route path='/profil/sejarah-sekolah' element={<SejarahSekolah/>}/>
            <Route path='/profil/visi-misi' element={<VisiMisi/>}/>
            <Route path='/profil/lambang-mars' element={<LambangMars/>}/>
            <Route path='/profil/struktur-organisasi' element={<StrukturOrganisasi/>}/>
            <Route path='/profil/pendidik-tenagakependidikan' element={<PendidikTenagaKependidikan/>}/>
            <Route path='/profil/peserta-didik' element={<PesertaDidik/>} />
            <Route path='/kurikulum/kalender-pendidikan' element={<KalenderPendidikan/>}/>
            <Route path='/kurikulum/jadwal-kbm' element={<JadwalKBM/>}/>
            <Route path='/kurikulum/kompetensi-keahlian' element={<KompetensiKeahlian/>}/>
            <Route path='/kurikulum/kompetensi-keahlian-detail/:id' element={<KompetensiKeahlianSlash/>}/>
            <Route path='/kurikulum/kompetensi-keahlian/desain-komunikasi-visual' element={<DesainKomunikasiVisual/>}/>
            <Route path='/humas/pkl' element={<Pkl/>}/>
            <Route path='/humas/bkk' element={<Bkk/>}/>
            <Route path='/humas/du-di' element={<DuDi/>}/>
            <Route path='/sarpras' element={<SarPras/>}/>
            
            <Route path='/kesiswaan/prestasi-siswa' element={<PrestasiSiswa/>}/>
            <Route path='/kesiswaan/prestasi-siswa/:id' element={<PrestasiDetail/>}/>
            <Route path='/kesiswaan/ekstrakurikuler' element={<Ekstrakurikuler/>}/>
            <Route path='/kesiswaan/osis' element={<Osis/>}/>
            <Route path='/postingan/galeri' element={<Galeri/>}/>
            <Route path='/postingan/berita' element={<Berita/>}/>
            <Route path='/postingan/berita/:id' element={<DetailBerita/>}/>
            <Route path='/postingan/agenda' element={<Agenda/>}/>
            <Route path='/postingan/agenda/:id' element={<DetailAgenda/>}/>
            <Route path='/kontak' element={<Kontak/>}/>
          </Route>

          {/* terbatas */}
          <Route>
            <Route path='/login' element={<Login/>}/>
          </Route>
          
          <Route path="/admin"  element={<RequireAuth loginPath={'/login'}> <AdminLayout/> </RequireAuth>}>
            <Route index element={<DashboardAdmin />} />
            <Route path='/admin/kalender-pendidikan-admin' element={<KalenderPendidikanAdmin />} />
            <Route path='/admin/jadwal-kbm-admin' element={<JadwalKbmAdmin />} />
            
            <Route path='/admin/ubah-peserta-didik' element={<UbahPesertaDidik/>}/>
            <Route path='/admin/ubah-peserta-didik/:id' element={<UbahPesertaDidik/>}/>
            <Route path='/admin/ubah-struktur-organisasi' element={<UbahStrukturOrganisasi/>}/>
            <Route path='/admin/ubah-pendidik' element={<UbahPendidik/>}/>
            <Route path='/admin/ubah-tenaga-kependidikan' element={<UbahTenagaKependidikan/>}/>
            <Route path='/admin/kompetensi-keahlian-admin' element={<KompetensiKeahlianAdmin/>}/>
            <Route path='/admin/ubah-sarpras' element={<UbahSarpras/>}/>
            
            <Route path='/admin/galeri-admin' element={<GaleriAdmin/>}/>
            <Route path='/admin/posting-berita' element={<PostingBerita/>}/>
            <Route path='/admin/posting-agenda' element={<PostingAgenda/>}/>
            <Route path='/admin/galeri-admin' element={<GaleriAdmin/>}/>
            <Route path='/admin/guru-admin' element={<GuruAdmin/>}/>
            <Route path='/admin/posting-prestasi' element={<PostingPrestasi/>}/>
            <Route path='/admin/detail-prestasi' element={<DetailPrestasi/>}/>
            <Route path='/admin/detail-prestasi/:id' element={<DetailPrestasi/>}/>
            <Route path='/admin/ubah-ekstrakurikuler' element={<UbahEkstrakurikuler/>}/>
            <Route path='/admin/ekstrakurikuler-detail' element={<EkstrakurikulerDetail/>}/>
            <Route path='/admin/ekstrakurikuler-detail/:id' element={<EkstrakurikulerDetail/>}/>
            <Route path='/admin/kompetensi-keahlian-detail' element={<KompetensiKeahlianForm/>}/>
            <Route path='/admin/kompetensi-keahlian-detail/:id' element={<KompetensiKeahlianForm/>}/>
            <Route path='/admin/form-berita' element={<FormBerita/>}/>
            <Route path='/admin/form-berita/:id' element={<FormBerita/>}/>
            <Route path='/admin/form-agenda' element={<FormAgenda/>}/>
            <Route path='/admin/form-agenda/:id' element={<FormAgenda/>}/>
            <Route path='/admin/form-guru' element={<FormGuru/>}/>
            <Route path='/admin/form-guru/:id' element={<FormGuru/>}/>
            <Route path='/admin/form-galeri' element={<FormGaleri/>}/>
            <Route path='/admin/form-galeri/:id' element={<FormGaleri/>}/>
            <Route path='/admin/form-sarpras/' element={<FormSarpras/>}/>
            <Route path='/admin/form-sarpras/:id' element={<FormSarpras/>}/>
            
            <Route path='/admin/osis-admin' element={<OsisAdmin/>}/>
          </Route>
        </Routes>
      </AuthProvider>
      </>
    )
  
}

export default App;
