import React,{useState, useEffect, useRef,} from "react";
import { Link,  useNavigate, useParams } from "react-router-dom";
import axios from "../../../api/axios";
import Swal from 'sweetalert2';
import { getCookie } from "../../../utils/cookieUtils";

const JURUSAN_URL ="/kompetensi-keahlian";
const GURU_URL ="/data-pendidik";

const FormGuru = () => {
  const navigate = useNavigate();
  const userRef = useRef();
  const { id } = useParams();
  const [namaGuru, setNamaGuru] = useState("");
  const [gambarGuru, setGambarGuru] = useState("");
  const [jurusanGuru, setJurusanGuru] = useState("");
  
  const [data, setData] = useState([]);
  const token = getCookie("_auth");

 
 
  //sweet alert
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  useEffect(() => {
    fetchJurusan();
  }, []);
  

  //ambil nilai jurusan
  const fetchJurusan = async () => {
    try{
        const resp = await axios.get(JURUSAN_URL, {
            headers: { 
              "Accept" :"application/json"
           },
            // withCredentials:true
          });
          setData(resp.data?.data);
          // console.log(data);
    }catch (error) {
        console.log(error?.response?.data);
      }
  };

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
    //cari id
    if (id) {
        // Fetch existing data based on ID
        fetchGuru();
      }
    //ambil id jurusan  
      if (data.length > 0) {
        const defaultJurusan = data[0].id; // Ambil id jurusan pertama dari data jurusan
        setJurusanGuru(defaultJurusan);
      }
  }, [id,data]);

  //get
  const fetchGuru = async () => {
    try {
      const resp = await axios.get(`${GURU_URL}/${id}`, {
        headers: {
          "Accept": "appliaction/json",
        },
      });
      setNamaGuru(resp.data?.data?.name);
      setGambarGuru(resp.data?.data?.image);
      setJurusanGuru(resp.data?.data?.kompetensi?.name)
    } catch (error) {
      console.log(error?.response?.data);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  const formData = new FormData();
  formData.append("name", namaGuru);
  formData.append("file", gambarGuru);
  formData.append("kompetensi_id", jurusanGuru);

  console.log("jurusanGuru:", jurusanGuru);
    try {
      if(id){
          // Edit
          const resp = await axios.post(`${GURU_URL}/${id}?_method=PATCH`, formData, {
            headers: {
              "Content-type": "multipart/form-data",
              "Accept": "appliaction/json",
              "Authorization": `Bearer ${token}`
            },
          });
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Mengedit Guru'
          });
          navigate("/admin/guru-admin", { replace: true });
        } 
        // Post
        else {
          const resp = await axios.post(GURU_URL, formData, {
            headers: { 
              "Content-type": "multipart/form-data",
              "Accept" : "appliaction/json",
              "Authorization" : `Bearer ${token}`
          },
            // withCredentials:true
          });
    
          setNamaGuru("");
          setGambarGuru("");
          setJurusanGuru("");
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Menambahkan Guru'
          });
          navigate("/admin/guru-admin", { replace: true });
        }
      } catch (error) {
          Toast.fire({
            icon: 'error',
            title: error?.response?.data?.message
          });
          console.log(error?.response?.data);
        }
      };


  return (
    <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="/img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col ">
                            <h1 style={{ fontWeight:"bold",color:"#061745",textAlign:"center" }}>{id ? "Edit Guru" : "Tambah Guru"}</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <form onSubmit={handleSubmit}>
                            <div className="form-group">

                            {gambarGuru && (
                            <div className="mb-3">
                                <label className="form-label">Gambar saat ini:</label>
                                <img src={'https://smkn6jakarta.sch.id/app/storage/data-pendidik/'+gambarGuru} alt="Gambar saat ini" className="img-fluid" />
                            </div>
                            )} 

                            <label htmlFor="namaGuru">Nama Guru :</label>
                            <input
                                type="text"
                                className="form-control"
                                id="namaGuru"
                                value={namaGuru}
                                onChange={(e) => setNamaGuru(e.target.value)}
                            />
                            </div>
                            <div className="form-group mt-4">
                            <label htmlFor="GambarGuru">Gambar Guru :</label>
                            <input
                                type="file"
                                className="form-control"
                                id="GambarGuru"
                                accept="image/*"
                                onChange={(e) => setGambarGuru(e.target.files[0])}
                            />
                            <div id="UploadImage" className="form-text">ukuran maksimal 2mb</div> 
                            </div>
                            <div className="forn-group mt-4">
                            <label htmlFor="form-select">Kompetensi Keahlian Guru :</label>
                            <select className="form-select" value={jurusanGuru} onChange={(e) => setJurusanGuru(e.target.value)}>
                            <option selected>Pilih Jurusan Guru</option>
                            {data.map((item, index) => {
                              // console.log("jurusan.id:", jurusanGuru); // Tambahkan log untuk melihat nilai item.id
                              return (
                                <option key={item.id} value={item.id} selected={item.id === jurusanGuru}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>

                            </div>
                            <div className="form-group">
                            <button type="submit" disabled={id ? true : false} className="btn btn-primary mt-3">Submit{" "}</button>
                            <button type="submit" disabled={id ? false : true} className="btn btn-warning ms-3 mt-3" >Edit{" "}</button>
                            <Link to="/admin/guru-admin" className="btn btn-primary mt-3 ms-3" style={{ background:"#061745" }}>Kembali</Link>
                            </div>
                        </form>
                            
                                                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

  );
};

export default FormGuru;
