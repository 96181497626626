import React,{useState, useEffect, useRef,} from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "../../api/axios";

const EKSKUL_URL = "/ekstrakurikuler";

const Ekstrakurikuler = () =>{
    const [data,setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchEkskul();
      }, []);

      const fetchEkskul = async () => {
        try{
            const resp = await axios.get(EKSKUL_URL, {
                headers: { 
                //   "Authorization" : `Bearer ${accessToken}`
                "Accept": "application/json"
               },
                // withCredentials:true
              });
              setData(resp.data?.data);
              console.log(resp.data?.data);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };

      // Konfigurasi halaman
    // const photosPerPage = 9;
    // const totalPages = Math.ceil(data.length / photosPerPage);
    // const indexOfLastPhoto = currentPage * photosPerPage;
    // const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
    // const currentPhotos = data.slice(indexOfFirstPhoto, indexOfLastPhoto);

    // // Fungsi untuk pindah ke halaman berikutnya
    // const goToNextPage = () => {
    //     setCurrentPage((prevPage) => prevPage + 1);
    // };

    // // Fungsi untuk pindah ke halaman sebelumnya
    // const goToPreviousPage = () => {
    //     setCurrentPage((prevPage) => prevPage - 1);
    // };

    // // Fungsi untuk pindah ke halaman tertentu
    // const goToPage = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff",padding:"20px 40px" }}>
                    <div className="row">
                        <div className="col" >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/kesiswaan/ekstrakurikuler" style={{ color:"#000000" }}>Kesiswaan <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Ekstrakurikuler</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5" style={{ padding:"10px 80px" }}>
                        <div className="col text-center">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Ekstrakurikuler</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",marginTop:"5px",margin:"auto" }}/>
                            
                            <div className="ekstrakurikuler mt-5">
                                <div className="baristiga row row-cols-1 row-cols-md-3  align-items-center">
                                {data.map((ekskul) => (
                                    <div key={ekskul.id} className="col g-3">
                                        <div className="card mb-3">
                                        <img src={'https://smkn6jakarta.sch.id/app/storage/ekstrakurikuler/'+ekskul.image} className="card-img-top" alt="gambar-ekskul"/>
                                        <div className="card-body" style={{ backgroundColor:"#FFB606",color:"#fff",padding:"10px" }}>
                                            <h5 className="card-title" style={{ fontWeight:"bold",fontSize:"23px" }}>{ekskul.name}</h5>
                                        </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default Ekstrakurikuler;