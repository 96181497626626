import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import DOMPurify from 'dompurify';
import { format } from "crypto-js";
import SEO from '@americanexpress/react-seo';
import { Config } from "../config";

const BERITA_URL = "/berita-sekolah";
const PRESTASI_URL = "/prestasi-peserta-didik";
const GALERI_URL = "/galeri-sekolah/list";
const JURUSAN_URL = "/kompetensi-keahlian";

const Home = () => {

    const [dataBerita, setDataBerita] = useState([]);
    const [dataPrestasi, setDataPrestasi] = useState([]);
    const [dataGaleri, setDataGaleri] = useState([]);
    const [dataJurusan, setDataJurusan] = useState([]);

    useEffect(() => {
        fetchHome();
    }, []);

    const fetchHome = async () => {
        try {
            // Menggunakan Promise.all untuk menggabungkan dua permintaan
            const [beritaResp, prestasiResp, galeriResp, jurusanResp] = await Promise.all([
                axios.get(BERITA_URL, {
                    headers: {
                        "Accept": "application/json",
                    },
                }),
                axios.get(PRESTASI_URL, {
                    headers: {
                        "Accept": "application/json",
                    },
                }),
                axios.get(GALERI_URL, {
                    headers: {
                        "Accept": "application/json",
                    },
                }),
                axios.get(JURUSAN_URL, {
                    headers: {
                        "Accept": "application/json",
                    },
                }),
            ]);

            // Mengatur data ke dalam state
            setDataBerita(beritaResp?.data?.data);
            setDataPrestasi(prestasiResp?.data?.data);
            setDataGaleri(galeriResp?.data?.data);
            setDataJurusan(jurusanResp?.data?.data);
            //   console.log(beritaResp?.data?.data);
            //   setDataGuru(guruResp?.data?.data);
            //   console.log(guruResp?.data?.data);
        } catch (error) {
            console.log(error?.response?.data);
        }
    };

    //fungsi split tanggal
    const formatDate = (date) => {
        const dateParts = date.split("-");

        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];

        // Ubah angka bulan menjadi nama bulan
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const monthIndex = parseInt(month) - 1;
        const monthName = monthNames[monthIndex];

        return {
            day,
            month: monthName,
            year,
        };
    };

    // fungsi sanitize dan potong kata 
    const sanitizeAndTrimContent = (content) => {
        const sanitizedContent = DOMPurify.sanitize(content);
        const plainTextContent = sanitizedContent.replace(/<[^>]+>/g, '');
        if (plainTextContent.length > 100) {
            return `${plainTextContent.substring(0, 100)}...`;
        }
        return plainTextContent;
    };

    const title = `${Config.siteName}`;
    const description = `${Config.description}`;

    return (

        <div className="container-fluid p-0">

            <SEO
                title = { title }
                description = { description }
                keywords = {['smkn 6', 'smkn 6 jakarta', 'smk negeri 6', 'smk 6']}
                siteUrl = { Config.baseURL }
                image = {{
                    src: `${Config.logo}`,
                }}
                openGraph = {{
                    title: { title },
                    description: { description },
                    image: {
                      src: `${Config.logo}`,
                      alt: { title },
                    }
                  }}
                twitterCard = {{
                    title: { title },
                    description: { description },
                    image: {
                      src: `${Config.logo}`,
                      alt: { title },
                    }
                }}
            />

            {/* <!-- carousel --> */}
            <div className="home-carousel">
                <div className="row">
                    <div className="col">
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="/img/Foto SMKN 6 Jakarta.png" className="d-block w-100 img-fluid" alt="..." style={{ height: "350px", objectFit: "cover" }} />
                                </div>
                                <div className="carousel-item">
                                    <img src="/img/Foto Carousel - 2.jpg" className="d-block w-100 img-fluid" alt="..." style={{ height: "350px", objectFit: "cover" }} />
                                </div>
                                <div className="carousel-item">
                                    <img src="/img/Foto Carousel - 3.png" className="d-block w-100 img-fluid" alt="..." style={{ height: "350px", objectFit: "cover" }} />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Berita --> */}
            <div className="home-berita py-5 " style={{ backgroundColor: "#fff" }}>
                <div className="container">
                    <div className="text-center">
                        <h3 style={{ color: "#061745", fontWeight: "bold", fontSize: "30px" }}>Berita Terbaru</h3>
                        <img src="./img/garis12227-k76-200h.png" alt="" /><img src="./img/garis12227-k76-200h.png" alt="" className="ms-2" />
                    </div>
                    <div className="row mt-5">
                        {dataBerita.map((post) => {
                            const { day, month, year } = formatDate(post.date);
                            return (
                                <div className="col-lg-4 mb-4 px-2" key={post.id}>
                                    <div className="card h-100 border-0 rounded-3 shadow wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay=".2s">
                                        <Link to={`/postingan/berita/${post.id}`}>
                                            <div className="wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay=".2s">
                                                <figure className="effect-jazz">
                                                    <img src={'https://smkn6jakarta.sch.id/app/storage/berita-sekolah/' + post.image} className="card-img-top" alt="..." style={{ height: "240px", objectFit: "cover" }} />
                                                </figure>
                                                <div style={{ backgroundColor: "rgba(6,23,69,0.7)", marginTop: "-110px", position: "absolute" }} className="text-white p-2 rounded">
                                                    <p className="mb-0 fw-bold fs-2 text-center">{day}</p>
                                                    <p className="mb-0 mt-0" style={{ fontSize: "small" }}>{`${month}, ${year}`}</p>
                                                </div>
                                            </div>
                                            <div className="card-body text-start">
                                                <span className="d-inline-block text-muted small"><i className="bi bi-person-fill fs-6"></i> smkn6jkt</span>
                                                <h5 className="card-title mt-2 fw-bold" style={{ color: "#061745", fontSize: "20px" }}>{post.title.length > 64 ? `${post.title.substring(0, 64)}...` : post.title}</h5>
                                                <div className="card-text">
                                                    <span style={{ color: "#666666" }} dangerouslySetInnerHTML={{ __html: sanitizeAndTrimContent(post.content), }}></span>
                                                    <p style={{ fontWeight: "bold" }}>Read More <i className="bi bi-chevron-double-right" style={{ fontSize: "smaller" }}></i></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row text-center">
                        <div className="col">
                            <Link to="/postingan/berita" className="show-berita shadow-sm">Tampilkan Semua Berita</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Prestasi Terbaru --> */}
            <div className="home-prestasi" style={{ backgroundColor: "#061745" }}>
                <div className="row">
                    <div className="col text-center mb-5 mt-3">
                        <h3 className="text-center text-white mt-3" style={{ fontWeight: "bold", fontSize: "30px" }}>Prestasi Terbaru</h3>
                        <img src="./img/garis12227-k76-200h.png" alt="" /><img src="./img/garis12227-k76-200h.png" alt="" className="ms-2" />
                        {dataPrestasi.map((award) => (
                            <Link to={`/kesiswaan/prestasi-siswa/${award.id}`} key={award.id}>
                                <div className="container-card mt-3 mb-3" >
                                    <div className="row justify-content-center">
                                        <div className="col">
                                            <div className="card mb-3 m-auto wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay=".2s" style={{ maxWidth: "80%" }}>
                                                <div className="row g-0">
                                                    <div className="col-md-4 wow animate__animated animate__zoomIn effect-jazz overflow-hidden" data-wow-duration="1s" data-wow-delay=".2s">
                                                        <img src={'https://smkn6jakarta.sch.id/app/storage/prestasi-peserta-didik/' + award.image} style={{ objectFit: "cover" }} className="w-100 h-100 rounded-start" alt="..." />
                                                    </div>
                                                    <div className="col-md-8 text-start">
                                                        <div className="card-body">
                                                            <h5 className="card-title" style={{ color: "#002147;", fontWeight: "bold" }}>{award.title}</h5>
                                                            <p style={{ color: "#666666", fontSize: "small" }}><i className="bi bi-calendar2-week"></i> {award.date}</p>
                                                            <hr width="100%" />
                                                            <h6 style={{ color: "#666666", fontSize: "small" }}>{`Skala ${award.scale}`}</h6>


                                                            <p className="card-text" dangerouslySetInnerHTML={{ __html: sanitizeAndTrimContent(award.content), }}></p>
                                                            <br />
                                                            <h6><i className="bi bi-people-fill"></i> {award.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        <div className="col mt-4">
                            <Link to="/kesiswaan/prestasi-siswa" className="show-prestasi shadow-sm">Tampilkan Semua Prestasi</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Galeri --> */}
            <div className="home-galeri" style={{ backgroundColor: "#E7E7E7" }}>
                <div className="row">
                    <div className="col text-center mb-3 mt-5">
                        <h3 className="text-center" style={{ color: "#061745", fontWeight: "bold", fontSize: "30px" }}>Galeri Sekolah</h3>
                        <img src="./img/garis12227-k76-200h.png" alt="" /><img src="./img/garis12227-k76-200h.png" alt="" className="ms-2" />
                        <div className="row mx-auto my-auto justify-content-center mt-3 mb-4 wow animate__animated animate__zoomInDown" data-wow-duration="2s" data-wow-delay=".2s">
                            <div id="recipeCarousel" className="carousel slide" data-bs-ride="carousel">
                                {dataGaleri.map((gambar, index) => {
                                    if (index % 4 === 0) {
                                        const slideItems = dataGaleri.slice(index, index + 4);
                                        const slideItemsLength = slideItems.length;
                                        if (slideItemsLength < 4) {
                                            const additionalItems = dataGaleri.slice(0, 4 - slideItemsLength);
                                            slideItems.push(...additionalItems);
                                        }
                                        return (
                                            <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                                                <div className="row">
                                                    {slideItems.map((item) => (
                                                        <div className="col-md-3" key={item.id}>
                                                            <div className="card">
                                                                <div className="card-img">
                                                                    <img src={'https://smkn6jakarta.sch.id/app/storage/galeri-sekolah/' + item.image} className="img-fluid" alt="galeri" style={{ objectFit: "cover", height: "240px" }} />
                                                                </div>
                                                                <div className="card-img-overlay" style={{ marginTop: "170px" }}><p className="rounded p-2" style={{ background: "#061745", color: "#fff" }}>{item.title}</p></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                                <a className="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                </a>
                                <a className="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                </a>
                            </div>

                        </div>
                        <div className="col mb-3">
                            <Link to="/postingan/galeri" className="show-galeri shadow-sm">Tampilkan Semua</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Program Keahlian --> */}
            <div className="home-jurusan" style={{ backgroundColor: "#fff" }}>
                <div className="row">
                    <div className="col text-center mb-5 mt-5">
                        <h3 className="text-center" style={{ color: "#061745", fontWeight: "bold", fontSize: "30px" }}>Program Keahlian</h3>
                        <img src="./img/garis12227-k76-200h.png" alt="" /><img src="./img/garis12227-k76-200h.png" alt="" className="ms-2" />
                        <div className="container mt-5 wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay=".2s">
                            <div className="row row-cols-1 row-cols-md-3 g-4 align-items-center">
                                {dataJurusan.map((kompetensi) => (
                                    <Link to={`/kurikulum/kompetensi-keahlian-detail/${kompetensi.id}`} key={kompetensi.id}>
                                        <div className="col g-3 wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay=".3s">
                                            <div className="position-relative">
                                                <div className="image d-flex align-items-center justify-content-center">
                                                    <img src={'https://smkn6jakarta.sch.id/app/storage/kompetensi-keahlian/' + kompetensi.index_image} alt="Gambar" className="img-fluid rounded img-content" style={{ width: "360px", height: "173px", objectFit: "cover" }} />
                                                    <div className="content">
                                                        <h4 style={{ fontWeight: "bold", fontSize: "30px" }}>{kompetensi.name}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Data Warga Sekolah --> */}
            <div className="home-data-sekolah" style={{ backgroundColor: "#061745" }}>
                <div className="row">
                    <div className="col text-center mb-3 mt-5">
                        <h3 className="text-center text-white" style={{ fontWeight: "bold", fontSize: "30px" }}>Data Warga Sekolah</h3>
                        <img src="./img/garis12227-k76-200h.png" alt="" /><img src="./img/garis12227-k76-200h.png" alt="" className="ms-2" />
                        <div className="container mt-5 text-center">
                            <div className="row row-cols-1 row-cols-md-4 align-items-center mb-5 justify-content-center text-center">
                                <div className="col g-3 mx-auto">
                                    <div className="murid text-white border border-white d-flex flex-column justify-content-center" style={{ width: "100%", maxWidth: "265px", height: "240px" }}>
                                        <i className="bi bi-people" style={{ fontSize: "46px", color: "#FFB606" }}></i>
                                        <h3 style={{ fontSize: "75px", fontWeight: "bolder" }}>853</h3>
                                        <h5>MURID</h5>
                                    </div>
                                </div>
                                <div className="col g-3 mx-auto">
                                    <div className="murid text-white border border-white d-flex flex-column justify-content-center" style={{ width: "100%", maxWidth: "265px", height: "240px" }}>
                                        <i className="bi bi-people" style={{ fontSize: "46px", color: "#FFB606" }}></i>
                                        <h3 style={{ fontSize: "75px", fontWeight: "bolder" }}>49</h3>
                                        <h5>GURU</h5>
                                    </div>
                                </div>
                                <div className="col g-3 mx-auto">
                                    <div className="murid text-white border border-white d-flex flex-column justify-content-center" style={{ width: "100%", maxWidth: "265px", height: "240px" }}>
                                        <i className="bi bi-people" style={{ fontSize: "46px", color: "#FFB606" }}></i>
                                        <h3 style={{ fontSize: "75px", fontWeight: "bolder" }}>13</h3>
                                        <h5>TENAGA KEPENDIDIKAN</h5>
                                    </div>
                                </div>
                                <div className="col g-3 mx-auto">
                                    <div className="murid text-white border border-white d-flex flex-column justify-content-center" style={{ width: "100%", maxWidth: "265px", height: "240px" }}>
                                        <i className="bi bi-people" style={{ fontSize: "46px", color: "#FFB606" }}></i>
                                        <h3 style={{ fontSize: "75px", fontWeight: "bolder" }}>23</h3>
                                        <h5>ROMBEL</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- Hubungi Kami --> */}
            <div className="home-kontak" style={{ backgroundColor: "#E7E7E7" }}>
                <div className="row">
                    <div className="col text-center mb-5 mt-5">
                        <h3 className="text-center" style={{ color: "#061745", fontWeight: "bold", fontSize: "30px" }}>Hubungi Kami</h3>
                        <img src="./img/garis12227-k76-200h.png" alt="" /><img src="./img/garis12227-k76-200h.png" alt="" className="ms-2" />
                        <div className="container">
                            <div className="row row-cols-1 row-cols-md-3 row-cols-sm-1">
                                <div className="col-md-4 col-sm-12 g-5 text-center">
                                    <div className="row">
                                        <div className="col-10 g-3">
                                            <h5 style={{ fontWeight: "bold" }}>SMK Negeri 6 Jakarta</h5>
                                            <p>Jl. Prof. Joko Sutono SH No. 2A</p>
                                        </div>
                                        <div className="col-2 g-3">
                                            <div className="icon">
                                                <i className="bi bi-buildings text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-10 g-3">
                                            <h5 style={{ fontWeight: "bold" }}>Telepon</h5>
                                            <Link to="tel:0217208718">(021) 7208718</Link>
                                        </div>
                                        <div className="col-2 g-3">
                                            <div className="icon">
                                                <i className="bi bi-telephone text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-10 g-3">
                                            <h5 style={{ fontWeight: "bold" }}>Email</h5>
                                            <Link to="mailto:smk6@smkn6jakarta.sch.id">smk6@smkn6jakarta.sch.id</Link>
                                        </div>
                                        <div className="col-2 g-3">
                                            <div className="icon">
                                                <i className="bi bi-envelope text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 g-5 text-center">
                                    <img src="./img/img-smkn6/1. HOME/LogoSMK6-hubungikami.png" alt="" style={{ width: "247px", height: "292px" }} className="img-fluid" />
                                </div>
                                <div className="col-md-4 col-sm-12 g-5 text-center">
                                    <div className="row">
                                        <div className="col-2 g-3">
                                            <div className="icon">
                                                <i className="bi bi-instagram text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-10 g-3">
                                            <h5 style={{ fontWeight: "bold" }}>Instagram</h5>
                                            <Link to="https://www.instagram.com/smkn6jkt/">@smkn6jkt</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2 g-3">
                                            <div className="icon">
                                                <i className="bi bi-tiktok text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-10 g-3">
                                            <h5 style={{ fontWeight: "bold" }}>TikTok</h5>
                                            <Link to="https://www.tiktok.com/@smkn6jkt">@smkn6jkt</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2 g-3">
                                            <div className="icon">
                                                <i className="bi bi-youtube text-white" style={{ fontSize: "34px" }}></i>
                                            </div>
                                        </div>
                                        <div className="col-10 g-3">
                                            <h5 style={{ fontWeight: "bold" }}>Youtube</h5>
                                            <Link to="https://www.youtube.com/@smknegeri6jakarta">SMK Negeri 6 Jakarta</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home;