import React, { useState,useEffect,useRef } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "../../../api/axios";


const JURUSAN_URL = "/kompetensi-keahlian";
const APP_URL = `https://smkn6jakarta.sch.id/app/storage/${JURUSAN_URL}/`

const KompetensiKeahlian = () =>{

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchJurusan();
      }, []);

      const fetchJurusan = async () => {
        try{
            const resp = await axios.get(JURUSAN_URL, {
                headers: { 
                    "Accept": "appliaction/json",
               },
                // withCredentials:true
              });
              setData(resp.data?.data);
            //   console.log(resp.data?.data);
        }catch (error) {
            console.log(error?.response?.data);
          }
      };
    return(
        <> 
        <div className="container-fluid p-0">
            <div className="bg">  
                    <div className="row">
                        <div className="col">
                            <img src="../img/HEADER.png" alt="" className="mr-3 me-3 w-100 d-block"  />
                        </div>
                    </div>
            </div>
            <div className="content">
                <div className="container shadow" style={{ backgroundColor:"#fff" }}>
                    <div className="row">
                        <div className="col" style={{ padding:"20px 40px" }} >
                            <Link to="/" style={{ color:"#000000" }}>Beranda</Link> <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> <Link to="/kurikulum/kompetensi-keahlian" style={{ color:"#000000" }}>Kurikulum <i class="bi bi-circle text-warning ms-2 me-2" style={{ fontSize:"10px" }}></i> Kompentensi-Keahlian</Link>
                        <hr style={{ width:"100%" }}/>
                        </div>
                    </div>
                    <div className="row mb-5 text-center" style={{ padding:"10px 0px" }}>
                        <div className="col">
                            <h1 style={{ fontWeight:"bold",color:"#061745" }}>Kompetensi Keahlian</h1>
                            <hr style={{ width:"15%",border: "none",borderTop: "4px solid #FFB606",opacity:"100%",margin:"20px auto" }}/>
                            <div className="home-jurusan">
                                <div className="row">
                                    <div className="col text-center mb-3">
                                        <div className="container mt-5">
                                        <div className="row row-cols-1 row-cols-md-3 g-4 align-items-center">
                                            {data.map((isi)=>(
                                                <Link to={`/kurikulum/kompetensi-keahlian-detail/${isi.id}`} className="col g-3" key={isi.id}>                       
                                                    <div className="position-relative">
                                                        <div className="image d-flex align-items-center justify-content-center">
                                                            
                                                        <img src={APP_URL+isi.index_image} alt="Gambar" className="img-fluid rounded img-content" style={{ width: "360px",height: "173px" }}/>
                                                        <div className="content">
                                                            <h4 style={{ fontWeight: "bold",fontSize: "30px" }}>{isi.name}</h4>
                                                        </div>
                                                        </div>
                                                    </div>                        
                                                </Link>
                                            ))}
                                        </div>
                                        </div>
                                    </div>
                                </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default KompetensiKeahlian;